/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { Badge, Pagination, Table } from 'react-bootstrap';
import { useBlockLayout, usePagination, useResizeColumns, useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useCatalog } from '../../hooks';
import { toCapitalize, toLocalDateTimeFormat } from '../../shared/utils/Helper';
import './ShipmentDocsConfigList.scss';
import { PrimaryButton } from '../../shared/components/GenericComponent';
import { ArrowRightWhite, EditIconGreen } from '../../assets/img/imageExport/images';
import { Loader, PaginationComponent } from '../../shared/components';

const ShipmentDocsConfigList = ({ data, isLoading }) => {
  const { materialTypes } = useCatalog();
  const history = useHistory();

  const navigateToConfigPage = (materialId) => {
    history.push({
      pathname: '/systemconfig/documents-configure/master',
      state: {
        isMaster: false,
        materialId,
        materialName: getNameById(materialId),
      },
    });
  };

  const getNameById = (id) => {
    const material = materialTypes?.find((item) => item?.id === id);
    return material ? material?.name : '-';
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Business Vertical',
        Cell: ({ row }) => {
          return getNameById(row?.original?.materialId);
        },
      },
      {
        Header: 'No. of Sections',
        accessor: 'configJson',
        Cell: ({ row }) => {
          return JSON.parse(row?.original?.configJson)?.length || '-';
        },
      },
      {
        Header: 'Updated On',
        accessor: 'createdAt',
        Cell: ({ row }) => {
          return toLocalDateTimeFormat(row?.original?.createdAt) || '-';
        },
      },
      {
        Header: 'Configuration Status',
        accessor: 'configurationStatus',
        Cell: ({ row }) => {
          const status = row?.original?.configurationStatus?.toLowerCase();
          let badgeClass = '';

          if (status === 'pending') {
            badgeClass = 'badge-pending';
          } else if (status === 'completed') {
            badgeClass = 'badge-completed';
          }

          return (
            <div className="text-left">
              <Badge pill className={`badge ${badgeClass}`}>
                {toCapitalize(status)}
              </Badge>
            </div>
          );
        },
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => {
          return (
            <div className="text-left">
              {row?.original?.configurationStatus?.toLowerCase() === 'pending' ? (
                <PrimaryButton
                  buttonStyle={{
                    display: 'flex',
                    padding: '8px 12px',
                    borderRadius: '8px',
                  }}
                  label={
                    <span>
                      Configure
                      <ArrowRightWhite />
                    </span>
                  }
                  onClick={() => {
                    navigateToConfigPage(row?.original?.materialId);
                  }}
                />
              ) : (
                <PrimaryButton
                  buttonStyle={{
                    display: 'flex',
                    padding: '8px 12px',
                    backgroundColor: '#fff',
                    color: '#156751',
                    borderRadius: '8px',
                  }}
                  label={
                    <span>
                      <EditIconGreen /> Edit
                    </span>
                  }
                  onClick={() => {
                    navigateToConfigPage(row?.original?.materialId);
                  }}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    page,
    previousPage,
    nextPage,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: data || [],
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useBlockLayout,
    useResizeColumns
    // usePagination
  );

  return (
    <div className="ShipmentDocsConfigList table-wrapper">
      <div {...getTableProps()} className="table">
        <div className="table-header" style={{ backgroundColor: '#5b7682' }}>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div {...getTableBodyProps()} className="table-body">
            {rows?.map((row) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr">
                  {row?.cells.map((cell) => (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render('Cell')}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShipmentDocsConfigList;
