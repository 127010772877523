import React, { useState } from 'react';
import { Card, Col, Form, Image, Row } from 'react-bootstrap';
import { MdLocationOn } from '@react-icons/all-files/md/MdLocationOn';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Address, Amount, DoShow, itemUnitDisplay } from '../../shared/components/GenericComponent';
import { Location, VectorBook } from '../../assets/img/imageExport/images';
import {
  BOOKED_QTY,
  DISPATCHED_QTY,
  FULLFILLED_ON,
  ITEM_NAME,
  ORDER_QTY,
  PRICE,
  SALE_ORDER_ID,
} from '../../shared/utils/StringConstants';
import {
  formatNumberToIndianSystem,
  toLocalDate,
  toPaisa,
  toServerDate,
} from '../../shared/utils/Helper';
import { useCatalog } from '../../hooks';
import Quantity from '../../shared/components/Quantity';
import { parseToRequiredDecimal } from '../../shared/utils/utilityFunctions';
import DisplayField from '../../shared/components/DisplayField';
import { FormField } from '../../shared/components';
import useResponsive from '../../hooks/useResponsive';
import Theme from '../../theme/Theme';

const AssignSaleOrderForPO = (props) => {
  const {
    selectSaleOrder,
    setSelectSaleOrder,
    saleOrderDetails,
    setSaleOrderDetails,
    saleOrderData,
    saleOrderId,
    handleSelectRequistion,
    handleSelectSaleOrder,
    pickupScheduleDate,
    setPickupScheduleDate,
    restprops,
  } = { ...props };

  const { items } = useCatalog();
  const { isMobile } = useResponsive();
  const colors = Theme.Colors;

  const shippingAddress = () =>
    saleOrderData?.recycler?.address?.find((add) => add?.id === saleOrderData?.shippingAddressId) ||
    'NA';

  const {
    rate = 0,
    qty = 0,
    balanceQty = 0,
    dispatchedQty = 0,
    purchaseOrders = [],
    priceValidityDate = '',
    id = id,
    recycler = {
      creditLimit: 0,
      availableCredits: 0,
      businessName: '',
    },
    grossMargin = 0,
    grossMarginPercent = 0,
    mpSalesOrderItems = {},
    balanceQuantity = 0,
    dispatchedQuantity = 0,
  } = saleOrderData;

  const itemId = mpSalesOrderItems?.[0]?.itemId;
  const itemUnit = itemUnitDisplay(itemId);
  const soData = [
    { id: 0, label: SALE_ORDER_ID, value: id, size: 1 },
    { id: 1, label: FULLFILLED_ON, value: toLocalDate(priceValidityDate), size: 1 },
    { id: 2, label: ITEM_NAME, value: items?.[itemId]?.name, size: 3 },
    {
      id: 3,
      label: PRICE,
      value: <Amount value={toPaisa(mpSalesOrderItems?.[0]?.price)} postfix={`/ ${itemUnit}`} />,
      size: 1,
    },
    {
      id: 4,
      label: ORDER_QTY,
      value: <Quantity value={mpSalesOrderItems?.[0]?.quantity} postfix={itemUnit} />,
      size: 2,
    },
    {
      id: 5,
      label: BOOKED_QTY,
      value: (
        <Quantity
          value={parseToRequiredDecimal(mpSalesOrderItems?.[0]?.quantity - balanceQuantity, 3)}
          postfix={itemUnit}
        />
      ),
      size: 2,
    },
    {
      id: 6,
      label: DISPATCHED_QTY,
      value: <Quantity value={parseToRequiredDecimal(dispatchedQuantity, 3)} postfix={itemUnit} />,
      size: 2,
    },
  ];
  return (
    <Card
      key={saleOrderId}
      className="mb-0 mt-3 requistion-card"
      onClick={() => {
        setSelectSaleOrder(saleOrderId);
        setSaleOrderDetails(saleOrderData);
      }}
      style={
        selectSaleOrder === saleOrderId
          ? {
              backgroundColor: colors.fg_primary,
              border: `1px solid  ${colors.brand_primary}`,
            }
          : {}
      }
      {...restprops}>
      <Card.Body className="p-3">
        <Row>
          <Col md={6}>
            <span className="font-bold">{saleOrderData?.recycler?.businessName || 'NA'}</span>
          </Col>
          <Col md={6}>
            <Row>
              <Col>
                <span
                  className="ml-2"
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#667085',
                    fontFamily: 'font-semibold',
                  }}>
                  Available Credits :
                </span>
                <span
                  className="ml-2"
                  style={{ fontSize: '12px', fontWeight: '600', color: '#344054' }}>
                  {`₹ ${formatNumberToIndianSystem(
                    saleOrderData?.recycler?.availableCredits || '0.00'
                  )}`}
                </span>
              </Col>
              <Col>
                <span
                  className="ml-2"
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#667085',
                    fontFamily: 'font-semibold',
                  }}>
                  Total Credit limit :
                </span>
                <span
                  className="ml-2"
                  style={{ fontSize: '12px', fontWeight: '600', color: '#344054' }}>
                  {`₹ ${formatNumberToIndianSystem(
                    saleOrderData?.recycler?.creditLimit || '0.00'
                  )}`}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col xs={12} md={12}>
            <span className="d-flex">
              <Image className="mr-2 mt-1" height="14" src={Location} />
              <Address value={shippingAddress(saleOrderData)} short />
              <span
                className="ml-2"
                style={{ borderRight: `1px solid ${colors.border_spacing}` }}
              />
              <span className="ml-2">
                <Image className="mr-2" height="14" src={VectorBook} />
                {saleOrderData?.shipments?.length || 0}
                {'  '}
                Shipment
              </span>
            </span>
          </Col>
        </Row>
        <hr />
        <Row className="g-1">
          {soData?.map((so) => {
            return (
              <Col md={so.size}>
                <DisplayField label={so?.label} value={so?.value} isHorizontal={isMobile} />
              </Col>
            );
          })}
        </Row>
        <DoShow show={selectSaleOrder === saleOrderId}>
          <hr />
          <Row>
            <Col>
              <Form>
                <Form.Group id="scheduledTime">
                  <FormField label="Update Pickup Schedule Date & Time" labelClass="required">
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        timePicker: true,
                        singleDatePicker: true,
                        locale: {
                          format: 'DD-MMM-YYYY h:mm a',
                        },
                        drops: 'bottom',
                        parentEl: '#scheduledTime',
                      }}
                      startDate={new Date()}
                      onApply={(event, picker) => {
                        setPickupScheduleDate(toServerDate(picker.startDate, false));
                      }}
                      onCancel={(event, picker) => {
                        setPickupScheduleDate(toServerDate(new Date(), false));
                      }}>
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: '38vh' }}
                        defaultValue=""
                        value={
                          pickupScheduleDate
                            ? toLocalDate(pickupScheduleDate, 'DD-MMM-YYYY h:mm a')
                            : null
                        }
                      />
                    </DateRangePicker>
                  </FormField>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </DoShow>
      </Card.Body>
    </Card>
  );
};

export default AssignSaleOrderForPO;
