const EventManager = (() => {
  let instance;

  function createInstance() {
    const object = {
      subscribers: {},
      unhandledPublishes: [],
    };
    return object;
  }

  return {
    getInstance() {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export const subscribe = (handle, callback) => {
  const eventManager = EventManager.getInstance();
  eventManager.subscribers[handle] = callback;

  // This is to allow actions to be taken on elements which are not currently mounted.
  // When the components do get mounted (and if they do subscribe), the event is replayed.
  // eslint-disable-next-line no-restricted-syntax
  for (const unhandledPublish of eventManager.unhandledPublishes) {
    if (unhandledPublish.handle === handle) {
      // eslint-disable-next-line no-use-before-define
      publish(unhandledPublish.handle, unhandledPublish.event, unhandledPublish.payload);
    }
  }
};

export const publish = (handle, event, payload) => {
  const eventManager = EventManager.getInstance();
  if (eventManager.subscribers[handle]) {
    eventManager.subscribers[handle](event, payload);
  } else {
    eventManager.unhandledPublishes.push({ handle, event, payload });
  }
};
