/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import DocumentSectionMasterPage from './DocumentSectionMasterPage';
import DocumentSectionConfigPage from './DocumentSectionConfigPage';
import { useShipmentDocumentsConfiguration } from '../../hooks/hooks';

const DocumentSectionMainPage = (props) => {
  const location = useLocation();
  const { isMaster = true, materialId = 1, materialName = 'Plastic' } = location.state || {};

  const [
    {
      getDocumentMasterSectionInfoError,
      getDocumentMasterSectionInfoLoading,
      getDocumentMasterSectionInfoValue,

      postDocumentMasterSectionInfoError,
      postDocumentMasterSectionInfoLoading,
      postDocumentMasterSectionInfoValue,

      getMaterialsConfigByIdError,
      getMaterialsConfigByIdValue,
      getMaterialsConfigByIdLoading,

      updateMaterialsConfigByIdError,
      updateMaterialsConfigByIdLoading,
      updateMaterialsConfigByIdValue,
    },
    {
      doGetDocumentMasterSectionInfo,
      doPostDocumentsMasterSection,
      doGetMaterialsConfigById,
      doUpdateMaterialsConfigById,
    },
  ] = useShipmentDocumentsConfiguration();

  useEffect(() => {
    if (isMaster) {
      doGetDocumentMasterSectionInfo();
    } else {
      doGetMaterialsConfigById(materialId);
    }
  }, [isMaster, materialId, doGetDocumentMasterSectionInfo, doGetMaterialsConfigById]);

  const formik = useFormik({
    initialValues: {
      documentConfigDetails: isMaster
        ? postDocumentMasterSectionInfoValue || getDocumentMasterSectionInfoValue || []
        : updateMaterialsConfigByIdValue || getMaterialsConfigByIdValue || [],
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return isMaster ? (
    <DocumentSectionMasterPage
      formik={formik}
      doPostDocumentsMasterSection={doPostDocumentsMasterSection}
      isLoading={getDocumentMasterSectionInfoLoading}
    />
  ) : (
    <DocumentSectionConfigPage
      isLoading={getMaterialsConfigByIdLoading}
      formik={formik}
      materialId={materialId}
      materialName={materialName}
      doUpdateMaterialsConfigById={doUpdateMaterialsConfigById}
    />
  );
};
export default DocumentSectionMainPage;
