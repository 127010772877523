import React, { useEffect, useState } from 'react';
import { isNull, isUndefined } from 'lodash';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { FormField } from '../../shared/components';
import { PrimaryButton } from '../../shared/components/GenericComponent';
import { toLocalDateTime } from '../../shared/utils/Helper';
import './AddSectionModal.scss';
import { DOCUMENT_SECTION_MASTER_ERROR_MESSAGES } from '../../shared/constants/Constants';

const AddSectionModal = ({
  showSectionModal,
  setShowSectionModal,
  formik,
  editIndex,
  setEditIndex,
  enumGenerator,
  defaultKeys,
}) => {
  const [sectionName, setSectionName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!isNull(editIndex)) {
      setSectionName(formik?.values?.documentConfigDetails?.[editIndex]?.sectionName);
    } else {
      setSectionName('');
    }
  }, [editIndex]);

  const handleSaveSection = () => {
    if (
      (formik?.values?.documentConfigDetails || []).some(
        (section, index) =>
          index !== editIndex &&
          section?.sectionName?.toLowerCase()?.replace(/\s/g, '') ===
            sectionName?.trim()?.toLowerCase()?.replace(/\s/g, '')
      )
    ) {
      setErrorMessage(DOCUMENT_SECTION_MASTER_ERROR_MESSAGES.DUPLICATE_SECTION);
      return;
    }
    if (isNull(editIndex)) {
      const trimmedSectionName = sectionName.trim();
      const sectionEnum = enumGenerator(trimmedSectionName);

      if (defaultKeys.includes(sectionEnum)) {
        setErrorMessage(`Section ${DOCUMENT_SECTION_MASTER_ERROR_MESSAGES?.EXISTING_KEYS}`);
        return;
      }

      setErrorMessage('');
      const newSection = {
        sectionName: trimmedSectionName,
        sectionId: Number(toLocalDateTime(new Date(), 'DDMMYYYYhhmmssms')),
        sectionStatus: false,
        documents: [],
        dataPoints: [],
        isDefault: true,
        sectionEnum,
      };
      const updatedDocumentConfigDetails = [...formik.values.documentConfigDetails, newSection];

      formik.setFieldValue('documentConfigDetails', updatedDocumentConfigDetails);
    } else {
      formik.setFieldValue(`documentConfigDetails[${editIndex}].sectionName`, sectionName.trim());
    }

    setEditIndex(null);
    setSectionName('');
    setShowSectionModal(false);
  };

  const handleCancel = () => {
    setErrorMessage('');
    setEditIndex(null);
    setSectionName('');
    setShowSectionModal(false);
  };
  return (
    <Modal
      className="AddSectionModal"
      centered
      size="md"
      show={showSectionModal}
      onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{isNull(editIndex) ? 'Add Section' : 'Edit Section'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="m-4">
          <Form>
            <Form.Row>
              <Form.Group as={Col} md={12}>
                <Form.Label>Section Name</Form.Label>
                <FormField>
                  <Form.Control
                    type="text"
                    placeholder="Enter Section name"
                    value={sectionName}
                    onChange={(e) => {
                      setSectionName(e.target.value);
                    }}
                  />
                </FormField>
                {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ borderRadius: '8px' }}
          className="border"
          variant="light"
          onClick={handleCancel}>
          Cancel
        </Button>
        <PrimaryButton
          buttonStyle={{ borderRadius: '8px' }}
          label="Save"
          onClick={handleSaveSection}
          disabled={!sectionName?.trim()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AddSectionModal;
