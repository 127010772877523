import axios from 'axios';
import ApiEndpoints from '../shared/constants/ApiEndpoints';
import { buildUrl, transformParams, transformParamsForAggregator } from '../shared/utils/Helper';
import { FilterParamMap } from '../shared/constants';
// eslint-disable-next-line import/named
import { SERVICE } from '../shared/constants/Constants';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.headers.common.appCode = process.env.REACT_APP_CODE;

// axios.defaults.headers.common.appCode = process.env.recyclerId;

const localConfig = JSON.parse(localStorage.getItem('local') || '{}');
if (localConfig.server) {
  axios.defaults.baseURL = localConfig.server;
}
if (localConfig.services) {
  let s = JSON.stringify(ApiEndpoints);
  Object.keys(localConfig.services).forEach((service) => {
    const localService = localConfig.services[service];
    const re = new RegExp(`/${service}/`, 'g');
    s = s.replace(re, `/${localService}/`);
  });
  Object.assign(ApiEndpoints, JSON.parse(s));
}

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    throw error?.response;
  }
);
/*
axios.interceptors.request.use(request => {
  return request
});

*/

export async function login(payload) {
  const endpoint = ApiEndpoints.AUTH.LOGIN;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function googleAuthLogin(params) {
  const endpoint = ApiEndpoints.AUTH.GOOGLE_OAUTH2_AUTHENTICATE;
  const response = await axios.post(endpoint, null, {
    params,
  });
  return response;
}

export async function validateToken(params) {
  const endpoint = ApiEndpoints.AUTH.VALIDATE_AUTHENTICATED_USER;
  const response = await axios.get(endpoint, {
    params,
  });
  return response;
}
export async function resetPassword(payload) {
  const endpoint = ApiEndpoints.AUTH.REQUEST_RESET_PASSWORD;
  const response = (
    await axios.post(endpoint, payload, {
      headers: { 'Content-Type': 'text/plain' },
    })
  ).data;
  return response;
}

export async function createPasswordFromLink(payload) {
  const endpoint = ApiEndpoints.AUTH.CREATE_PASSWORD;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function fetchAccount() {
  const endpoint = ApiEndpoints.USER.ACCOUNT;
  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function changePassword(payload) {
  const endpoint = ApiEndpoints.USER.CHANGE_PASSWORD;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function updateAccount(payload) {
  const endpoint = ApiEndpoints.USER.ACCOUNT;
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function updateDevices(body) {
  const endpoint = ApiEndpoints.USER.DEVICES;
  const response = (await axios.post(endpoint, body)).data;
  return response;
}

export async function fetchCitiesByPincode(params) {
  const endpoints = ApiEndpoints.CITY.ZIPCODE;
  return axios.get(endpoints, { params });
}

export async function fetchCitiesByState(stateId) {
  const endpoints = buildUrl(ApiEndpoints.CITY.STATE, { stateId });
  return (await axios.get(endpoints)).data;
}

export async function gstinRemainder(params) {
  const endpoint = ApiEndpoints.AGGREGATOR.SEND_GSTIN_REMAINDER;
  const response = (await axios.post(endpoint, null, { params })).data;
  return response;
}

export async function bankRemainder(params) {
  const endpoint = ApiEndpoints.AGGREGATOR.SEND_BANK_DETAILS_REMAINDER;
  const response = (await axios.post(endpoint, null, { params })).data;
  return response;
}

export async function initAppApis() {
  const endpoints = [
    axios.get(ApiEndpoints.CATALOG.ITEMS),
    axios.get(ApiEndpoints.REGION),
    axios.get(ApiEndpoints.CATALOG.ITEM_QPARAM),
    axios.get(ApiEndpoints.OTHER.BUSINESS_TYPES),
    axios.get(ApiEndpoints.ITEMS_MODULE.MATERIAL_TYPE_TINY),
    axios.get(ApiEndpoints.USER_AND_ROLES.GET_ALL_ZONES),
    axios.get(ApiEndpoints.USER_AND_ROLES.GET_ALL_GROUPS),
    axios.get(ApiEndpoints.USER_AND_ROLES.GET_ALL_MODULES),
    axios.get(ApiEndpoints.USER_AND_ROLES.GET_ROLES),
    axios.get(ApiEndpoints.SEGMENTATION.GET_ACTIVE_SELLER_TYPES),
    // axios.get(ApiEndpoints.ORDER.SALE.GET_ALL_INCORRECT_DOCS),
  ];
  const response = await axios.all(endpoints);

  return response;
}

/**
 * DOCUMENT APIS
 */
export async function getSignedUrl(fileDetails, type) {
  const url =
    type === SERVICE.STAKEHOLDER
      ? ApiEndpoints.DOCUMENT.SIGNED_URL_STAKEHOLDER
      : ApiEndpoints.DOCUMENT.SIGNED_URL;
  return (await axios.post(url, fileDetails)).data;
}

export async function deleteDocument(id, type) {
  const url = buildUrl(
    type === SERVICE.STAKEHOLDER
      ? ApiEndpoints.DOCUMENT.DELETE_DOC_STAKEHOLDER + id
      : ApiEndpoints.DOCUMENT.DELETE_DOC,
    { id }
  );
  return type === SERVICE.STAKEHOLDER
    ? (await axios.put(url)).data
    : (await axios.delete(url)).data;
}

export async function deleteDocumentSaleOrder(id, payload) {
  const url = buildUrl(ApiEndpoints.DOCUMENT.DELETE_DOC, { id });
  return (await axios.delete(url, { data: payload })).data;
}

export async function deleteItemInvoice(id) {
  const url = buildUrl(ApiEndpoints.OTHER.DELETE_ITEM_INVOICE, { id });
  return (await axios.delete(url)).data;
}

export async function attachDocument(fileDetails) {
  const url =
    fileDetails.entity === 'RECYCLER'
      ? ApiEndpoints.DOCUMENT.ATTACH_DOCUMENT_STAKEHOLDER
      : ApiEndpoints.DOCUMENT.ATTACH_DOCUMENT;
  return (await axios.post(url, fileDetails)).data;
}

export async function changeDocumentType(payload) {
  const url = ApiEndpoints.DOCUMENT.CHANGE_DOC_TYPE;
  return (await axios.put(url, payload)).data;
}

/**
 * CATALOG APIS
 */

export async function fetchLogistics(params) {
  const endpoints = ApiEndpoints.LOGISTICS.FETCH;
  return axios.get(endpoints, { params });
}
export async function fetchGetLogistics(_params) {
  const params = transformParams(_params, FilterParamMap.MANAGE_LOGISTICS);
  const endpoints = ApiEndpoints.LOGISTICS.GET;
  return axios.get(endpoints, { params });
}
export async function fetchLogisticsLogs(params) {
  const endpoints = ApiEndpoints.LOGISTICS.FETCH_LOGISTIC;
  return axios.get(endpoints, { params });
}

export async function updateLogistic(payload) {
  const endpoints = ApiEndpoints.LOGISTICS.UPDATE;
  return (await axios.put(endpoints, payload)).data;
}
export async function updateLogisticLogs(payload) {
  const endpoints =
    payload.type === 'PAYMENT_TERMS_NUMBER_OF_DAYS'
      ? ApiEndpoints.LOGISTICS.FETCH_AGGREGATOR_NO_OF_DAYS
      : ApiEndpoints.LOGISTICS.UPDATE_LOGISTIC_COST;

  return (await axios.post(endpoints, payload)).data;
}
export async function createLogistic(payload) {
  const endpoints = ApiEndpoints.LOGISTICS.UPDATE;
  return (await axios.post(endpoints, payload)).data;
}

export async function deleteLogistic(data) {
  const endpoints = buildUrl(ApiEndpoints.LOGISTICS.DELETE);
  return (await axios.delete(endpoints, { data })).data;
}

/**
 * ITEM_INFORMATION MODULE
 */

export async function getAllTypesItemInfo(params) {
  const endpoints = ApiEndpoints.ITEMS_MODULE.ITEM_INFO;
  return axios.get(endpoints, { params });
}

export async function getAllTypes(params) {
  const endpoints = ApiEndpoints.ITEMS_MODULE.TYPES;
  return axios.get(endpoints, { params });
}

export async function createAddType(body) {
  const endpoint = ApiEndpoints.ITEMS_MODULE.ADD_TYPE;
  return axios.post(endpoint, body);
}

export async function createAddEntity(body) {
  const endpoint = ApiEndpoints.ITEMS_MODULE.ADD_ENTITY;
  return axios.post(endpoint, body);
}

export async function createCategory(body) {
  const endpoint = ApiEndpoints.ITEMS_MODULE.ADD_CATEGORIES;
  return axios.post(endpoint, body);
}

export async function getZohoItemName(id) {
  const endpoints = `${ApiEndpoints.ITEMS_MODULE.GET_ZOHO_ITEM_NAME}/${id}`;
  return axios.get(endpoints);
}

export async function updateStatus(payload) {
  const endpoint = ApiEndpoints.ITEMS_MODULE.UPDATE_STATUS;
  return axios.put(endpoint, payload);
}

export async function updateSellerStatus(param) {
  // eslint-disable-next-line max-len
  const endpoint = `${ApiEndpoints.ITEMS_MODULE.SELLER_STATUS}?agreementStatus=${param.agreementStatus}&sellerId=${param.sellerId}`;
  return axios.put(endpoint);
}

export async function updateitemRank(payload) {
  const endpoint = ApiEndpoints.ITEMS_MODULE.ITEM_RANK;
  return axios.put(endpoint, payload);
}

export async function updateItems(payload) {
  const endpoint = ApiEndpoints.ITEMS_MODULE.EDIT_ITEM_RANK;
  return axios.put(endpoint, payload);
}

export async function addItems(payload) {
  const endpoint = ApiEndpoints.ITEMS_MODULE.ITEM_RANK;
  return axios.post(endpoint, payload);
}

export async function AddQualityParameters(body) {
  const endpoint = ApiEndpoints.ITEMS_MODULE.ADD_QUALITY_PARAMS;
  return axios.post(endpoint, body);
}

export async function getItemsList(_params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.ITEMS);
  const endpoints = ApiEndpoints.ITEMS_MODULE.GET_ITEMS_LIST;
  return axios.get(endpoints, { params });
}

export async function getAllItemsLogs(id) {
  const endpoints = buildUrl(ApiEndpoints.ITEMS_MODULE.LOGS, { id });
  return (await axios.get(endpoints)).data;
}

export async function getAllCatalogItems(params) {
  const endpoints = ApiEndpoints.CATALOG.ITEMS;
  return axios.get(endpoints, { params });
}

export async function getCustomAttributesList(params) {
  const endpoints = ApiEndpoints.ITEMS_MODULE.CUSTOM_ATTRIBUTES;
  return axios.get(endpoints, { params });
}

export async function getStandardAttributesList(params) {
  const endpoints = ApiEndpoints.ITEMS_MODULE.STANDARD_ATTRIBUTES;
  return axios.get(endpoints, { params });
}

export async function createCustomAttributesList(body) {
  const endpoint = ApiEndpoints.ITEMS_MODULE.NEW_CUSTOM_ATTRIBUTES;
  return axios.post(endpoint, body);
}

export async function updateCustomAttributesList(body) {
  const endpoint = ApiEndpoints.ITEMS_MODULE.EDIT_CUSTOM_ATTRIBUTES;
  return axios.put(endpoint, body);
}

export async function createListingManually(body) {
  const endpoint = ApiEndpoints.ITEMS_MODULE.CREATE_LISTING_MANUALLY;
  return axios.post(endpoint, body);
}

export async function getItemCustomAttributes(params) {
  const endpoints = ApiEndpoints.ITEMS_MODULE.GET_CUSTOM_PARAMS_ITEM;
  return axios.get(endpoints, { params });
}

export async function getQualityParameterList(params) {
  // eslint-disable-next-line max-len
  const endpoints = `${ApiEndpoints.ITEMS_MODULE.GET_QUALITY_PARAM}?itemId.equals=${params?.itemId}`;
  return axios.get(endpoints);
}

export async function getAllReports(params) {
  const userReport = params.appCode;
  // eslint-disable-next-line max-len
  const endpoints = `${ApiEndpoints.CAMPAIGNS.REPORTS}?appCode.equals=${userReport}`;
  delete params.appCode;
  return axios.get(endpoints, { params });
}

export async function getAllUserInfo(params) {
  const reportRef = params.referrer;

  // eslint-disable-next-line max-len
  const endpoints = `${ApiEndpoints.CAMPAIGNS.USERS}?appCode.equals=MKT006&referrer.equals=${reportRef}`;
  delete params.referrer;
  return axios.get(endpoints, { params });
}

/**
 * RECYCLER APIS
 */
export async function getListingItem(params) {
  const endpoints = ApiEndpoints.LISTINGS.FETCH_LISTING_ITEM;
  axios.defaults.headers.common.recyclerId = params.recyclerId;
  delete params.recyclerId;
  return axios.get(endpoints, { params });
}

export async function changeBuyerPrice(payload) {
  const endpoints = ApiEndpoints.LISTINGS.LISTING_BUYER_PRICE;
  return (await axios.put(endpoints, payload)).data;
}

export async function splitListing(payload, primaryItemId) {
  const endpoints = ApiEndpoints.LISTINGS.SPLIT_LISTING;
  axios.defaults.headers.common.primaryItemId = primaryItemId;
  return (await axios.post(endpoints, payload)).data;
}

export async function createListing(payload, sellerId) {
  const endpoint = ApiEndpoints.LISTINGS.CREATE_LISTING;
  axios.defaults.headers.common.sellerId = sellerId;
  return axios.post(endpoint, payload);
}

export async function getRecyclers(_params) {
  if (_params?.data?.materialId) {
    axios.defaults.headers.common.materialTypeIds = _params?.data?.materialId;
    delete _params?.data?.headersFilter;
  }

  const params = transformParamsForAggregator(_params?.data, FilterParamMap.RECYCLER);
  const endpoints = ApiEndpoints.RECYCLER.FETCH_PAGE;
  return axios.get(endpoints, {
    params,
    cancelToken: params?.signal?.ourRequest?.token,
  });
}

export async function updateRecyclerAppLeadAgreementDoc(payload) {
  // axios.defaults.headers.common.sellerId = payload.sellerId;
  // eslint-disable-next-line max-len
  const endpoints = `${ApiEndpoints.RECYCLER.RECYCLER_APP_LEAD_AGREEMENT}?recyclerId=${payload?.recyclerId}`;
  return (await axios.put(endpoints, payload?.payload)).data;
}

export async function getRecyclersPage(_params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.RECYCLER);
  const endpoints = ApiEndpoints.RECYCLER.FETCH_PAGE;
  return axios.get(endpoints, { params });
}

export async function getRecyclerAppLeads(_params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.RECYCLER_LEADS);
  const endpoints = ApiEndpoints.RECYCLER.RECYCLER_APP_LEADS;
  return axios.get(endpoints, { params });
}

export async function getRecyclersItems(_params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.RECYCLER);
  const endpoints = ApiEndpoints.RECYCLER.FETCH_BY_ITEMS;
  return axios.get(endpoints, { params });
}

export async function getAllTransporters(params) {
  const endpoints = ApiEndpoints.TRANSPORTERS.FETCH;
  return axios.get(endpoints, { params });
}

export async function getAllTransportersWithPagination(_params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.TRANSPORTER);
  const endpoints = ApiEndpoints.TRANSPORTERS.GET;
  return axios.get(endpoints, { params });
}

export async function getTransporterById(id) {
  const endpoints = buildUrl(ApiEndpoints.TRANSPORTERS.DETAILS, { id });
  return (await axios.get(endpoints)).data;
}

export async function getRecyclerUser(params) {
  delete axios.defaults.headers.common.userId;

  if (params?.userId) {
    axios.defaults.headers.common.userId = params?.userId;
    delete params?.userId;
  }
  const endpoints = ApiEndpoints.RECYCLER.GET_RECYCLER_USER;
  return axios.get(endpoints, { params });
}

export async function getRecyclerAppLeadsById(params) {
  delete axios.defaults.headers.common.userId;

  // eslint-disable-next-line max-len
  const endpoints = `${ApiEndpoints.RECYCLER.RECYCLER_APP_LEAD_ID}?customerId=${params?.customerId}`;
  return axios.get(endpoints);
}

export async function createAddRecycler(body) {
  const endpoint = ApiEndpoints.RECYCLER.ADD_RECYCLER;
  axios.defaults.headers.common.userId = body.userId;
  const response = (await axios.post(endpoint, body.user)).data;
  return response;
}

export async function updateRecyclerUser(payload) {
  const endpoints = ApiEndpoints.RECYCLER.UPDATE_RECYCLER;
  axios.defaults.headers.common.userId = payload.userId;
  return (await axios.put(endpoints, payload.user)).data;
}

export async function updatePointOfContact(params) {
  const endpoints = ApiEndpoints.RECYCLER.UPDATE_POINT_OF_CONTACT;
  const response = (await axios.put(endpoints, null, { ...params })).data;
  return response;
}

export async function deleteRecyclerUser(payload) {
  const endpoints = ApiEndpoints.RECYCLER.DELETE_RECYCLER;
  axios.defaults.headers.common.userId = payload.userId;
  return (await axios.put(endpoints, payload.user)).data;
}

export async function convertBuyerToSeller(payload) {
  const endpoints = ApiEndpoints.RECYCLER.CONVERT_BUYER_TO_SELLER;
  return (await axios.put(endpoints, payload)).data;
}

export async function deactivateRecycler(params) {
  const endpoints =
    `${ApiEndpoints.RECYCLER.DEACTIVATE_RECYCLER}` +
    `?recyclerId=${params.recyclerId}&deactivate=${params.deactivate}&comment=${params.comment}`;
  return (await axios.put(endpoints)).data;
}

export async function updateRecyclerPreferredCategory(params) {
  const endpoints = ApiEndpoints.RECYCLER.RECYCLER_PREFERRED_CATEGORY;
  return (await axios.put(endpoints, null, { params })).data;
}

export async function updateRecyclerValidateDocuments(payload) {
  const endpoints = ApiEndpoints.RECYCLER.VALIDATE_DOCUMENTS;
  return (await axios.put(endpoints, payload)).data;
}

export async function createTransporter(payload) {
  const endpoints = ApiEndpoints.TRANSPORTERS.CREATE;
  return (await axios.post(endpoints, payload)).data;
}

export async function updateTransporter(payload) {
  const endpoints = ApiEndpoints.TRANSPORTERS.UPDATE;
  return (await axios.put(endpoints, payload)).data;
}
export async function deleteTransporter(id) {
  const endpoints = buildUrl(ApiEndpoints.TRANSPORTERS.DELETE, { id });
  return (await axios.delete(endpoints)).data;
}
export async function deleteListingItem(payload) {
  const endpoints = buildUrl(ApiEndpoints.LISTINGS.DELETE_LISTING_ITEM);
  return (await axios.put(endpoints, payload)).data;
}

export async function updateKam(params) {
  const endpoints = ApiEndpoints.AGGREGATOR.CHANGE_KAM;
  return (await axios.put(endpoints, null, { params })).data;
}
export async function getKamUsers(params) {
  const endpoints = ApiEndpoints.USERS.FETCH;
  return axios.get(endpoints, { params });
}
export async function getUsers(_params) {
  const endpoints = ApiEndpoints.USERS.FETCH;
  const params = transformParams(_params, FilterParamMap?.USERS);
  return axios.get(endpoints, { params });
}

export async function createKamUser(payload) {
  const endpoints = ApiEndpoints.KAM.CREATE;
  return (await axios.post(endpoints, payload)).data;
}

export async function updateKamUser(payload) {
  const endpoints = ApiEndpoints.KAM.UPDATE;
  return (await axios.put(endpoints, payload)).data;
}

export async function assignKamCities(payload) {
  const endpoints = ApiEndpoints.KAM.ASSIGN_CITIES;
  return (await axios.post(endpoints, payload)).data;
}

export async function getAggregators(_params) {
  if (_params?.data?.headersFilter) {
    axios.defaults.headers.common.materialTypes = _params?.data?.headersFilter?.materialId;
    delete _params?.data?.headersFilter;
  }
  const params = transformParamsForAggregator(_params?.data, FilterParamMap?.AGGREGATOR);
  const endpoints = ApiEndpoints.AGGREGATOR.FETCH;
  return axios.get(endpoints, { params, cancelToken: _params?.source?.token });
}

export async function getAggregatorById(id) {
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.DETAILS, { id });
  return (await axios.get(endpoints)).data;
}

export async function getAggregatorByCityId(params) {
  const endpoints = ApiEndpoints.AGGREGATOR.AGGREGATOR_BY_CITY_ID;
  return axios.get(endpoints, { params });
}

export async function markAsInternalAggregator(id, params) {
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.UPDATE_INTERNAL_USER, { id });
  return (await axios.put(endpoints, null, { params })).data;
}
export async function updateAggregatorAddress(id, params) {
  axios.defaults.headers.common.aggrId = id;
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.UPDATE_ADDRESS);
  return (await axios.put(endpoints, params)).data;
}

export async function ConvertSellerToBuyer(payload) {
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.CONVERT_SELLER_TO_BUYER);
  return (await axios.put(endpoints, payload)).data;
}

export async function getDeviceDetails(params) {
  const endpoints = ApiEndpoints.AGGREGATOR.GET_DEVICE_DETAILS;
  return (await axios.get(endpoints, { params })).data;
}

export async function getCustomerNotes(id) {
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.GET_NOTES, { id });
  return (await axios.get(endpoints)).data;
}

export async function getCampaignDetailsByAggregator(_params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.AGGREGATOR);
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.AGGREGATOR_CAMPAIGNS, { params });
  return axios.get(endpoints, { params });
}

export async function getAggregatorDefaultScore(params) {
  // const params = transformParamsForAggregator(_params);
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.AGGREGATOR_DEFAULTSCORE, { params });
  return axios.get(endpoints, { params });
}

export async function getAggregatorPendingActivities(params) {
  // const params = transformParamsForAggregator(_params);
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.AGGREGATOR_RATING_PENDINGACTIVITIES, {
    params,
  });
  return axios.get(endpoints, { params });
}

export async function getAggregatorRatingScores(params) {
  // const params = transformParamsForAggregator(_params);
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.AGGREGATOR_RATING_SCORES, {
    params,
  });
  return axios.get(endpoints, { params });
}

export async function getLeads(_params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.LEADS);
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.LEADS, {
    params,
  });
  return axios.get(endpoints, { params });
}

export async function gstManualEntry(payload) {
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.AGGREGATOR_GST_MANUAL_ENTRY);
  return (await axios.put(endpoints, payload)).data;
}

export async function uploadCsv(payload, headers) {
  const endpoint = ApiEndpoints.AGGREGATOR.UPLOAD_CSV;
  const response = (
    await axios.post(endpoint, payload, { headers: { 'Content-Type': 'text/plain', ...headers } })
  ).data;
  return response;
}

export async function addCustomerNote(id, payload) {
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.GET_NOTES, { id });
  // eslint-disable-next-line max-len
  return (await axios.post(endpoints, payload, { headers: { 'Content-Type': 'text/plain' } })).data;
}

export async function getRecyclerById(id) {
  const endpoints = buildUrl(ApiEndpoints.RECYCLER.DETAILS, { id });
  return (await axios.get(endpoints)).data;
}

export async function getUserTypeById(id) {
  const endpoints = buildUrl(ApiEndpoints.RECYCLER.USER_TYPE, { id });
  return (await axios.get(endpoints)).data;
}

export async function updateRecycler(payload) {
  const endpoints = ApiEndpoints.RECYCLER.UPDATE;
  if (payload?.deleteIds !== null) {
    axios.defaults.headers.common.deleteIds = payload?.deleteIds || '';
    delete payload?.deleteIds;
  }
  return (await axios.put(endpoints, payload)).data;
}

export async function createRecycler(payload, appCode) {
  const endpoints = ApiEndpoints.RECYCLER.CREATE;
  return (
    await axios.post(endpoints, payload, {
      headers: { appCode },
    })
  ).data;
}

export async function deleteRecycler(id) {
  const endpoints = buildUrl(ApiEndpoints.RECYCLER.DELETE, { id });
  return (await axios.delete(endpoints)).data;
}

export async function updateRecyclerItem(payload) {
  const endpoints = ApiEndpoints.RECYCLER.ADD_EDIT_RECYCLER_ITEMS;
  return (await axios.put(endpoints, payload)).data;
}

export async function fetchGstin(id) {
  const endpoints = ApiEndpoints.ADDRESS.GSTIN;
  const response = (await axios.get(endpoints + id)).data;
  return response;
}

export async function fetchAggregatorGstin(id) {
  const endpoints = ApiEndpoints.AGGREGATOR.AGGREGATOR_GSTIN;
  const response = (await axios.get(endpoints + id)).data;
  return response;
}

export async function updateAggregatorSellerStatus(payload) {
  const endpoints = ApiEndpoints.AGGREGATOR.AGGREGATOR_UPDATE_SELLER_STATUS;
  return (await axios.put(endpoints, payload)).data;
}

export async function getUserActivity(_params) {
  const endpoints = ApiEndpoints.AGGREGATOR.AGGREGATOR_USER_ACTIVITY;
  const params = transformParams(_params, FilterParamMap.USER_ACTIVITY);
  return axios.get(endpoints, { params });
}

export async function createAggregatorAppLeadAddress(payload) {
  delete axios.defaults.headers.common.sellerId;
  axios.defaults.headers.common.aggrId = payload.aggregatorId;
  const endpoints = ApiEndpoints.AGGREGATOR.APP_LEADS_ADDRESS;
  return (await axios.post(endpoints, payload?.payload)).data;
}

export async function updateAggregatorAppLeadAddress(payload) {
  delete axios.defaults.headers.common.sellerId;
  axios.defaults.headers.common.aggrId = payload.aggregatorId;
  const endpoints = ApiEndpoints.AGGREGATOR.APP_LEADS_ADDRESS;
  return (await axios.post(endpoints, payload?.payload)).data;
}

export async function createAggregatorAppLeadBankDetails(payload) {
  delete axios.defaults.headers.common.sellerId;
  const endpoints = ApiEndpoints.AGGREGATOR.APP_LEADS_BANK_DETAILS;
  return (await axios.post(endpoints, payload)).data;
}

export async function createPennyDropAppLeadBankDetails(payload) {
  delete axios.defaults.headers.common.sellerId;
  const endpoints = ApiEndpoints.AGGREGATOR.PENNY_DROP_BANK_DETAILS;
  return (await axios.put(endpoints, payload)).data;
}

export async function updateAggregatorAppLeadAgreementDoc(payload) {
  axios.defaults.headers.common.sellerId = payload.sellerId;
  // eslint-disable-next-line max-len
  const endpoints = `${ApiEndpoints.AGGREGATOR.APP_LEADS_AGREEMENT_DOC}?sellerId=${payload?.sellerId}`;
  return (await axios.put(endpoints, payload?.payload)).data;
}

export async function createAggregatorOnboard(payload) {
  const endpoints = ApiEndpoints.AGGREGATOR.AGGREGATOR_ONBOARD;
  return (await axios.post(endpoints, payload)).data;
}

export async function createAggregatorUser(payload) {
  delete axios.defaults.headers.common.sellerId;
  if (payload?.payloadObject) {
    axios.defaults.headers.common.userId = payload?.userId;
    delete payload?.userId;
  }
  const endpoints = ApiEndpoints.AGGREGATOR.CREATE_AGGREGATOR_USERS;
  return (await axios.post(endpoints, payload?.payloadObject)).data;
}

export async function updateAggregatorUser(payload) {
  delete axios.defaults.headers.common.sellerId;
  if (payload?.payloadObject) {
    axios.defaults.headers.common.userId = payload?.userId;
    delete payload?.userId;
  }

  const endpoints = ApiEndpoints.AGGREGATOR.EDIT_AGGREGATOR_USERS;
  return (await axios.put(endpoints, payload?.payloadObject)).data;
}

export async function deleteAggregatorUser(payload) {
  delete axios.defaults.headers.common.sellerId;
  if (payload) {
    axios.defaults.headers.common.userId = payload?.userId;
    delete payload?.userId;
  }

  const endpoints = ApiEndpoints.AGGREGATOR.DELETE_AGGREGATOR_USERS;
  return (await axios.put(endpoints)).data;
}

export async function getAggregatorUsers(params) {
  delete axios.defaults.headers.common.userId;

  if (params?.sellerId) {
    axios.defaults.headers.common.sellerId = params?.sellerId;
    delete params?.sellerId;
  }
  const endpoints = ApiEndpoints.AGGREGATOR.AGGREGATOR_USERS;
  return axios.get(endpoints, { params });
}

export async function fetchGstr2AData(action, _params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.GSTR_2A);
  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.GSTR_2A_DATA + action, { params });

  return axios.get(endpoints, { params });
}

export async function gst2Areports(action, _param) {
  const params = transformParamsForAggregator(_param, FilterParamMap.GSTR_2A);
  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.GSTR_2A_REPORTS + action, { params });
  return axios.get(endpoints, { responseType: 'blob', params });
}

export async function fetchGstr2APeriodData(payload) {
  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.GSTR_2A_PERIOD_DATA);
  return (await axios.post(endpoints, payload)).data;
}

export async function fetchGstrLogData(params) {
  const endpoints = ApiEndpoints.GSTR_RECONCILATION.GSTR_2A_FETCH_LOGDATA;
  return axios.get(endpoints, { params });
}

export async function fetchGstrSummaryData(payload) {
  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.GSTR_FETCH_SUMMARY_DATA);
  return (await axios.post(endpoints, payload)).data;
}

export async function fetchGstData(params) {
  const endpoints = ApiEndpoints.GSTR_RECONCILATION.GST_FETCH_DATA;
  return axios.get(endpoints, { params });
}

export async function fetchNotesData(params) {
  const endpoints = ApiEndpoints.GSTR_RECONCILATION.GST_FETCH_NOTES_DATA;
  return axios.get(endpoints, { params });
}

export async function fetchReconcilationData(action, _params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.GSTR_RECONCILE);

  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.RECONCILATION_DATA + action, {
    params,
  });
  return axios.get(endpoints, { params });
}

export async function fetchReconciledData(_params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.GSTR_RECONCILE);
  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.RECONCILED_DATA, { params });
  return axios.get(endpoints, { params });
}

export async function fetchReconcileDocumentData(_params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.GSTR_RECONCILE);
  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.RECONCILE_DOCUMENT_DATA, { params });
  return axios.get(endpoints, { params });
}

export async function fetchMatchingData(param1, param2, _params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.GSTR_RECONCILE);
  const endpoints = `${ApiEndpoints.GSTR_RECONCILATION.MATCH_BOOKS_2A_DATA}${param1}/${param2}`;
  const url = buildUrl(endpoints, { params });
  return axios.get(url, { params });
}

export async function fetchLinkData(payload) {
  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.LINK_DATA);
  return (await axios.post(endpoints, payload)).data;
}

export async function fetchAcceptData(payload) {
  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.ACCEPT_DATA);
  return (await axios.post(endpoints, payload)).data;
}

export async function fetchIgnoreData(payload) {
  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.IGNORE_DATA);
  return (await axios.post(endpoints, payload)).data;
}

export async function fetchPaymentSummary(_params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.GST_PAYMENTS);
  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.PAYMENT_SUMMARY, { params });
  return axios.get(endpoints, { params });
}

export async function downloadPaymentsReport(_param) {
  const params = transformParamsForAggregator(_param, FilterParamMap.GST_PAYMENTS);
  const endpoint = buildUrl(ApiEndpoints.GSTR_RECONCILATION.RECONCILE_REPORT);
  return axios.get(endpoint, { responseType: 'blob', params });
}

export async function fetchReconcileButtonStatus(payload) {
  const endpoints = buildUrl(ApiEndpoints.GSTR_RECONCILATION.RECONCILE_STATUS);
  return (await axios.get(endpoints, payload)).data;
}

export async function addSellerStatus(payload) {
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.ADD_SELLER_STATUS);
  return (await axios.post(endpoints, payload)).data;
}

export async function getSellerStatus(payload) {
  const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.GET_SELLER_STATUSES);
  return (await axios.get(endpoints, payload)).data;
}

export async function blockOrUnblockSeller(params) {
  const endpoints =
    `${ApiEndpoints.AGGREGATOR.BLOCK_OR_UNBLOCK_SELLER}` +
    `?seller_id=${params.seller_id}&deactivate=${params.deactivate}&comment=${params.comment}`;
  return (await axios.put(endpoints)).data;
}

/**
 * PURCHASE ORDER APIS
 */
export async function createPO(body) {
  const endpoint = ApiEndpoints.ORDER.PURCHASE.CREATE_PURCHASE_ORDER;
  const response = (await axios.post(endpoint, body)).data;
  return response;
}

export async function createPOAdmin(body) {
  const endpoint = ApiEndpoints.ORDER.PURCHASE.CREATE_PURCHASE_ORDER_ADMIN;
  axios.defaults.headers.common.recyclerId = body.purchaseOrder.recyclerId;
  delete body.purchaseOrder.recyclerId;
  const response = (await axios.post(endpoint, body)).data;
  return response;
}

export async function savePOAdmin(payload) {
  const endpoint = ApiEndpoints.ORDER.PURCHASE.SAVE_PURCHASE_ORDER;
  axios.defaults.headers.common.recyclerId = payload.purchaseOrder.recyclerId;
  delete payload.purchaseOrder.recyclerId;
  return (await axios.put(endpoint, payload)).data;
}

export async function fetchPO(_params, headerParams) {
  const params = transformParams(_params, FilterParamMap.PO);
  axios.defaults.headers.common.itemDetails = headerParams.itemDetails;
  const endpoints = ApiEndpoints.ORDER.PURCHASE.PURCHASE_ORDER_LIST;
  return axios.get(endpoints, { params });
}

export async function fetchPOById(id) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.PURCHASE.PURCHASE_ORDER_DETAILS, { id });
  return (await axios.get(endpoints)).data;
}

export async function deletePOById(id) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.PURCHASE.PURCHASE_ORDER_DETAILS, { id });
  return (await axios.delete(endpoints)).data;
}

export async function fetchQualityParams(id) {
  const endpoints = ApiEndpoints.CATALOG.ITEM_QPARAM;
  const response = (await axios.get(endpoints + id)).data;
  return response;
}

export async function updatePO(payload) {
  const endpoints = ApiEndpoints.ORDER.PURCHASE.UPDATE_PURCHASE_ORDER;
  return (await axios.put(endpoints, payload)).data;
}

export async function approvePO(payload) {
  axios.defaults.headers.common.recyclerId = payload.recyclerId;
  const endpoints = ApiEndpoints.ORDER.PURCHASE.APPROVE_PURCHASE_ORDER;
  return (await axios.put(endpoints, payload)).data;
}

export async function openPO(payload) {
  const endpoints = ApiEndpoints.ORDER.PURCHASE.OPEN_PURCHASE_ORDER;
  return (await axios.put(endpoints, payload)).data;
}

export async function recordPaymentPO(payload) {
  const endpoints = ApiEndpoints.ORDER.PURCHASE.RECORD_ADVANCE_PAYMENT;
  return (await axios.post(endpoints, payload)).data;
}

export async function rejectPO(id, params) {
  const url = buildUrl(ApiEndpoints.ORDER.PURCHASE.REJECT_PO, { id });
  const endpoint = `${url}?status=${params.status}&reason=${params.reason}`;
  return (await axios.put(endpoint)).data;
}

/**
 * SALE ORDER
 */
export async function fetchSaleOrders(_params) {
  if (_params?.data?.headersFilter) {
    axios.defaults.headers.common.items = _params?.data?.headersFilter?.items;
    axios.defaults.headers.common.materialTypeIds = _params?.data?.headersFilter?.materialId;
    delete _params?.data?.headersFilter;
  }
  const params = transformParams(_params?.data, FilterParamMap.SALE_ORDER);
  const endpoints = ApiEndpoints.ORDER.SALE.FETCH;
  return axios.get(endpoints, { params, cancelToken: _params?.source?.token });
}

export async function getSaleOrderById(id) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.GET, { id });
  return (await axios.get(endpoints)).data;
}

export async function deleteSaleOrder(id) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.DELETE, { id });
  return (await axios.delete(endpoints)).data;
}

export async function rejectSaleOrder(id, params) {
  const endpoint = buildUrl(ApiEndpoints.ORDER.SALE.REJECT, { id });
  return (await axios.put(endpoint, null, { params })).data;
}
export async function returnSaleOrder(params) {
  // eslint-disable-next-line max-len
  const endpoint = `${ApiEndpoints.ORDER.SALE.RETURN_SALE_ORDER}/${params.id}`;
  return (await axios.put(endpoint)).data;
}
export async function resellSaleOrder(params) {
  // eslint-disable-next-line max-len
  const endpoint = `${ApiEndpoints.ORDER.SALE.RESELL_SALE_ORDER}/${params.id}}`;
  return (await axios.put(endpoint, params)).data;
}
export async function saveAddress(payload, appCode, aggrId) {
  const endpoints = ApiEndpoints.AGGREGATOR.CREATE;
  return (
    await axios.post(endpoints, payload, {
      headers: { aggrId },
    })
  ).data;
}

export async function updateCustomerAddress(payload, appCode, aggrId) {
  const endpoints = ApiEndpoints.AGGREGATOR.UPDATE_CUSTOMER_ADDRESS;
  return (
    await axios.post(endpoints, payload, {
      headers: { aggrId },
    })
  ).data;
}

export async function updatePreferredMaterial(params) {
  const endpoint = ApiEndpoints.AGGREGATOR.UPDATE_PREFERRED_MATERIAL;
  return (await axios.put(endpoint, null, { params })).data;
}

export async function approveSaleOrder(params, payload) {
  // const endpoints = ApiEndpoints.ORDER.SALE.APPROVE;
  const id = params.shipmentId;
  const url = buildUrl(ApiEndpoints.ORDER.SALE.APPROVE, { id });
  const endpoints = `${url}?.shipmentId=${params.shipmentId}`;
  return (await axios.put(endpoints, payload)).data;
}

// const endpoint = buildUrl(ApiEndpoints.SALEORDER.UPDATE_SALESORDER_STATUS, { id });
// const points = `${endpoint}?status=${params.status}&reason=${params.rejectReason}`;
// return (await axios.put(points)).data;
export async function sendForApproval(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.AGGREGATOR_APPROVAL;
  return (await axios.put(endpoints, payload)).data;
}
export async function verifiedInvoiceQty(id, params, payload) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.VERIFY_QUANTITY, { id });
  return (await axios.put(endpoints, payload, { params })).data;
}
export async function adminVerificationDone(id, payload) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.ADMIN_VERIFY, { id });
  return (await axios.put(endpoints, payload)).data;
}
export async function accountsVerificationDone(id, payload) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.ACCOUNTS_APPROVAL, { id });
  return (await axios.put(endpoints, payload)).data;
}

export async function accountsSendEmail(id, payload) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.ACCOUNTS_SEND_EMAIL, { id });
  return (await axios.put(endpoints, payload)).data;
}

export async function transporterSaveInfo(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.ADD_TRANSPORT_INFO;
  return (await axios.put(endpoints, payload)).data;
}

export async function adminSendEmail(id, payload) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.SEND_EMAIL_ADMIN, { id });
  return (await axios.put(endpoints, payload)).data;
}

export async function businessHeadApproval(id) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.BUSINESS_HEAD_APPROVAL, { id });
  // axios.defaults.headers.common.listingId = listingId;
  return (await axios.put(endpoints)).data;
}

export async function businessHeadReject(id, payload) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.BUSINESS_HEAD_REJECT, { id });
  return (await axios.put(endpoints, payload)).data;
}

export async function changeKamSaleOrder(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.CHANGE_KAM;
  return (await axios.put(endpoints, payload)).data;
}

export async function addnewItemSo(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.ADD_SECOND_ITEM;
  return (await axios.put(endpoints, payload)).data;
}
export async function deleteItemSo(data) {
  const endpoints = ApiEndpoints.ORDER.SALE.DELETE_SO_ITEM;
  return (await axios.put(endpoints, data)).data;
}

export async function updateWeighBridgeDetails(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.UPDATE_AGGREGATOR_WEIGHBRIDGE_DETAILS;
  return (await axios.post(endpoints, payload)).data;
}

export async function notesSaleOrder(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.NOTES;
  return (await axios.post(endpoints, payload)).data;
}

export async function getTransporters() {
  const endpoints = ApiEndpoints.ORDER.SALE.FETCH_TRANSPORTERS;
  return (await axios.get(endpoints)).data;
}

export async function getVehicles() {
  const endpoints = ApiEndpoints.ORDER.SALE.FETCH_VEHICLES;
  return (await axios.get(endpoints)).data;
}
export async function getComments(id) {
  const endpoints = `${ApiEndpoints.ORDER.SALE.COMMENTS}?orderId.equals=${id}&size=200&page=0`;
  return (await axios.get(endpoints)).data;
}
export async function getTruckDelayComments() {
  const endpoints = ApiEndpoints.ORDER.SALE.DELAY_COMMENTS;
  return (await axios.get(endpoints)).data;
}
export async function getSyatemUser(id) {
  const endpoints = ApiEndpoints.USER.SYSTEM_USERS + id;
  return (await axios.get(endpoints)).data;
}
export async function addComments(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.COMMENTS;
  return (await axios.post(endpoints, payload)).data;
}

export async function addTruckDetails(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.ADD_UPDATE_TRUCK_DETAILS;
  return (await axios.post(endpoints, payload)).data;
}

export async function updateAssessment(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.UPDATE_ASSESSMENT;
  return (await axios.put(endpoints, payload)).data;
}

export async function updateTruckDetails(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.ADD_UPDATE_TRUCK_DETAILS;
  return (await axios.put(endpoints, payload)).data;
}

export async function reassignOrder(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.REASSIGN_ORDER;
  return (await axios.post(endpoints, payload)).data;
}

export async function uploadJournalVoucher(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.UPDATE_DOCS_UPLOAD;
  return (await axios.post(endpoints, payload)).data;
}

export async function requestImage(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.REQUEST_IMAGE;
  return (await axios.post(endpoints, payload)).data;
}

export async function addWeighBridgeInfo(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.WEIGHBRIDGE_INFO;
  return (await axios.put(endpoints, payload)).data;
}

export async function markMaterialLoaded(payload) {
  const endpoints = payload.isUpdate
    ? ApiEndpoints.ORDER.SALE.PICKUP_DETAILS
    : ApiEndpoints.ORDER.SALE.MARK_MATERIAL_LOADED;
  return (await axios.put(endpoints, payload)).data;
}

export async function markLogisticsDocsUpload(id) {
  const endpoints = `${ApiEndpoints.ORDER.SALE.LOGISTICS_DOC_UPLOADED}/${id}`;
  return (await axios.put(endpoints)).data;
}

export async function markPaymentDone(id) {
  const endpoints = `${ApiEndpoints.ORDER.SALE.MARK_PAYMENT_DONE}/${id}`;
  return (await axios.put(endpoints)).data;
}

export async function deletePayment(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.DELETE_PAYMENT;
  return (await axios.put(endpoints, payload)).data;
}

// export async function markDispatch(payload) {
//   const endpoints = payload.isUpdate
//     ? ApiEndpoints.ORDER.SALE.DISPATCH_DETAILS
//     : ApiEndpoints.ORDER.SALE.MARK_DISPATCH;
//   return (await axios.put(endpoints, payload)).data;
// }

export async function trackTuckById(id) {
  const endpoints = ApiEndpoints.ORDER.SALE.TRACKTRUCK + id;
  return (await axios.get(endpoints)).data;
}

export async function authorizeTracking(payload, env) {
  const endpoints =
    env === 'prod'
      ? ApiEndpoints.ORDER.SALE.AUTHORIZE_SUBSCRIBE_PROD
      : ApiEndpoints.ORDER.SALE.AUTHORIZE_SUBSCRIBE_STAGE;
  return (await axios.post(endpoints, payload)).data;
}

export async function sendDriverConsentSMS(payload, env) {
  const endpoints =
    env === 'prod'
      ? ApiEndpoints.ORDER.SALE.SEND_DRIVER_CONSENT_SMS_PROD
      : ApiEndpoints.ORDER.SALE.SEND_DRIVER_CONSENT_SMS_STAGE;
  return (await axios.post(endpoints, payload)).data;
}

export async function endTrackingDriverTrip(payload, env) {
  const endpoints =
    env === 'prod'
      ? ApiEndpoints.ORDER.SALE.END_DRIVER_TRIP_PROD
      : ApiEndpoints.ORDER.SALE.END_DRIVER_TRIP_STAGE;
  return (await axios.post(endpoints, payload)).data;
}

// export async function markReceivedByRecycler(payload) {
//   const endpoints = payload.isUpdate
//     ? ApiEndpoints.ORDER.SALE.DELIVERY_DETAILS
//     : ApiEndpoints.ORDER.SALE.RECEIVED_BY_RECYCLER;
//   return (await axios.put(endpoints, payload)).data;
// }

export async function rejectedQty(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.REJECTED_QTY;
  return (await axios.put(endpoints, payload)).data;
}

export async function unsubscribeMobileNumber(payload) {
  const endpoints = `${ApiEndpoints.ORDER.SALE.UNSUBSCRIBE_BY_MOBILE}?mobileNo=${payload?.mobile}`;
  return (await axios.put(endpoints)).data;
}

export async function markQCDone(payload) {
  const endpoints = payload.isUpdate
    ? ApiEndpoints.ORDER.SALE.DELIVERY_DETAILS
    : `${ApiEndpoints.ORDER.SALE.QC_DONE}`;

  return (await axios.put(endpoints, payload)).data;
}
export async function markEprDone(id) {
  const endpoints = `${ApiEndpoints.ORDER.SALE.MARK_EPR}/${id}`;
  return (await axios.put(endpoints)).data;
}

export async function updatePayment(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.UPDATE_PAYMENT;
  return (await axios.put(endpoints, payload)).data;
}

export async function recyclerQcParams(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.RECYCLER_QC_PARAMS;
  return (await axios.put(endpoints, payload)).data;
}

export async function getQualityParameters() {
  const endpoints = ApiEndpoints.CATALOG.ITEM_QPARAM;
  const response = (await axios.get(endpoints)).data;
  return response;
}

export async function debitNote(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.BUYER_SELLER_NOTES;
  return (await axios.put(endpoints, payload)).data;
}
export async function modifySale(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.MODIFY;
  return (await axios.put(endpoints, payload)).data;
}
export async function debitNoteSeller(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.DEBIT_NOTE_SELLER;
  return (await axios.put(endpoints, payload)).data;
}

export async function sendDebitNote(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.SEND_DEBIT_NOTE;
  return (await axios.put(endpoints, payload)).data;
}

export async function sendSMS(id) {
  const endpoints = ApiEndpoints.ORDER.SALE.SMS_DOWNLINK + id;
  return (await axios.get(endpoints)).data;
}

export async function sendConsentSMS(id) {
  const endpoints = `${ApiEndpoints.ORDER.SALE.SMS_CONSENTLINK}/${id}`;
  return (await axios.get(endpoints)).data;
}

export async function updateSellerGST(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.UPDATE_SELLER_GST;
  return (await axios.put(endpoints, payload)).data;
}

export async function getSellerGST(params) {
  const endpoints = ApiEndpoints.ORDER.SALE.GET_SELLER_GST;
  return (await axios.get(endpoints, { params })).data;
}

export async function makePayment(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.MAKE_PAYMENT;
  return (await axios.post(endpoints, payload)).data;
}

export async function makeGSTPayment(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.MAKE_GST_PAYMENT;
  return (await axios.post(endpoints, payload)).data;
}

export async function paymentReceived(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.PAYMENT_RECEIVED;
  return (await axios.put(endpoints, payload)).data;
}

export async function updatePaymentTerm(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.UPDATE_PAYMENT_TERM;
  return (await axios.put(endpoints, payload)).data;
}

export async function priceTrendSaleOrder(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.PRICE_TREND;
  return axios.put(endpoints, payload);
}

export async function priceTrendCommentsSaleOrder(id) {
  const endpoints = `${ApiEndpoints.ORDER.SALE.PRICE_TREND_COMMENTS}${id}`;
  return axios.get(endpoints);
}

export async function transporterPaymentTerm(id, params) {
  const endpoints = `${ApiEndpoints.ORDER.SALE.TRANSPORTER_PAYMENT_TERM}${id}`;
  return (await axios.put(endpoints, null, { params })).data;
}
// SHIPMENT API's

export async function markDispatch(payload) {
  const endpoints = payload.isUpdate
    ? ApiEndpoints.ORDER.SALE.DISPATCH_DETAILS
    : ApiEndpoints.ORDER.SALE.MARK_DISPATCH;
  return (await axios.put(endpoints, payload)).data;
}
export async function markReceivedByRecycler(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.RECEIVED_BY_RECYCLER;
  return (await axios.put(endpoints, payload)).data;
}
/**
 * REQUISITIONS
 * @return {Promise<any>}
 */
export async function getRequisitions(params) {
  const endpoints = ApiEndpoints.REQUISITION.FETCH;
  return (await axios.get(endpoints, { params })).data;
}

export async function getAllRequisitions(_params) {
  if (_params?.data?.headersFilter) {
    axios.defaults.headers.common.itemIds = _params?.data?.headersFilter?.itemIds;
    axios.defaults.headers.common.materialTypeIds = _params?.data?.headersFilter?.materialId;
    delete _params?.data?.headersFilter;
  }
  const params = transformParams(_params?.data, FilterParamMap.REQUISITION);
  const endpoints = ApiEndpoints.REQUISITION.FETCH_ALL_REQUISITIONS;
  return axios.get(endpoints, { params, cancelToken: _params?.source?.token });
}

export async function getRequisitionMetrics(_params) {
  if (_params?.data?.headersFilter) {
    // axios.defaults.headers.common.itemIds = _params?.data?.headersFilter?.itemIds;
    axios.defaults.headers.common.materialTypeIds = _params?.data?.headersFilter?.materialId;
    delete _params?.data?.headersFilter;
  }
  const params = transformParams(_params?.data, FilterParamMap.REQUISITION);
  const endpoints = ApiEndpoints?.SALEORDER.SALESORDER_METRICS;
  return axios.get(endpoints, { params, cancelToken: _params?.source?.token });
}

export async function getAllRequisitionsAssignRequistion(_params) {
  const params = transformParams(_params, FilterParamMap.REQUISITION);
  const endpoints = ApiEndpoints.REQUISITION.FETCH_ALL_REQUISITIONS_ASSIGN_REQ;
  return axios.get(endpoints, { params });
}

export async function getRequsitionById(id) {
  const endpoint = buildUrl(ApiEndpoints.REQUISITION.FETCH_REQUISITION_BY_ID, { id });
  return (await axios.get(endpoint)).data;
}

export async function createRequisition(payload) {
  const endpoint = buildUrl(ApiEndpoints.REQUISITION.CREATE_REQUISITION);
  return axios.post(endpoint, payload);
}

export async function updateRequisition(payload, old) {
  const endpoints = old
    ? ApiEndpoints.REQUISITION.FETCH
    : ApiEndpoints.REQUISITION.FETCH_ALL_REQUISITIONS;
  return old ? axios.post(endpoints, payload) : axios.put(endpoints, payload);
}

export async function fetchQuotes(_params) {
  const params = transformParams(_params, FilterParamMap.QUOTE);
  const endpoints = ApiEndpoints.REQUISITION.QUOTES.FETCH_UPDATE_QUOTE;
  return axios.get(endpoints, { params });
}

export async function updateRequisitionQuote(payload) {
  const endpoints = ApiEndpoints.REQUISITION.QUOTES.FETCH_UPDATE_QUOTE;
  return (await axios.put(endpoints, payload)).data;
}

export async function getAlternateRequisitions(id) {
  const endpoints = buildUrl(ApiEndpoints.REQUISITION.ALTERNATE, { id });
  return (await axios.get(endpoints)).data;
}

export async function reassignRequisition(payload) {
  const endpoints = ApiEndpoints.REQUISITION.REASSIGN;
  return (await axios.put(endpoints, payload)).data;
}

export async function assignPoToSaleOrder(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.ASSIGN_PO;
  return (await axios.put(endpoints, payload)).data;
}

// NEW BACKEND APIS FOR REQUISITIONS/SALESORDERS

export async function getSalesOrderById(saleOrderId) {
  const endpoint = `${ApiEndpoints.SALEORDER.FETCH_SALEORDER_BY_ID}/${saleOrderId}`;
  return (await axios.get(endpoint)).data;
}

export async function createSalesOrder(payload) {
  const endpoint = buildUrl(ApiEndpoints.SALEORDER.CREATE_SALESORDER);
  return axios.post(endpoint, payload);
}

export async function updateSalesOrder(payload) {
  const endpoint = buildUrl(ApiEndpoints.SALEORDER.UPDATE_SALESORDER);
  return axios.put(endpoint, payload);
}

export async function getAllSalesOrders(_params) {
  if (_params?.data?.headersFilter) {
    // axios.defaults.headers.common.itemIds = _params?.data?.headersFilter?.itemIds;
    axios.defaults.headers.common.materialTypeIds = _params?.data?.headersFilter?.materialId;
    delete _params?.data?.headersFilter;
  }
  const params = transformParams(_params?.data, FilterParamMap.SALESORDER);
  const endpoints = ApiEndpoints.SALEORDER.FETCH_ALL_SALESORDERS;
  return axios.get(endpoints, { params, cancelToken: _params?.source?.token });
}

export async function updateSalesOrderStatus(id, params) {
  // const endpoints =
  //   `${ApiEndpoints.SALEORDER.UPDATE_SALESORDER_STATUS}/${id}` +
  //   `?status=${params.status}&reason=${params.reason}`;
  const endpoint = buildUrl(ApiEndpoints.SALEORDER.UPDATE_SALESORDER_STATUS, { id });
  const points =
    `${endpoint}?status=${params.status}` +
    `&reason=${params.rejectReason}&isApprovedByUser=${params.isApprovedByUser}`;
  return axios.put(points);
}

// export async function addCustomerNote(id, payload) {
//   const endpoints = buildUrl(ApiEndpoints.AGGREGATOR.GET_NOTES, { id });
//   // eslint-disable-next-line max-len
//   return (await axios.post(endpoints, payload,
// { headers: { 'Content-Type': 'text/plain' } })).data;
// }

/**
 * CITIES
 */

export async function getActiveCities() {
  const endpoints = ApiEndpoints.CITY.FETCH;
  return (await axios.get(endpoints)).data;
}

export async function getListOfCities(_params) {
  const params = transformParams(_params, FilterParamMap.CITY);
  const endpoints = ApiEndpoints.CITY.FETCH_CITIES;
  return (await axios.get(endpoints, { params })).data;
}

export async function createCity(payload) {
  const endpoints = ApiEndpoints.CITY.CREATE;
  return (await axios.post(endpoints, payload)).data;
}

export async function updateDuplicateCity(payload) {
  const endpoints = ApiEndpoints.CITY.UPDATE_CITY;
  return (await axios.put(endpoints, payload)).data;
}

export async function updateCity(payload) {
  const endpoints = ApiEndpoints.CITY.UPDATE;
  return (await axios.put(endpoints, payload)).data;
}

export async function getPricing(params) {
  const endpoints = ApiEndpoints.PRICING;
  const response = (await axios.get(endpoints, { params })).data;
  return response;
}

export async function getRecykalMargin() {
  const endpoints = ApiEndpoints.RECYKAL_MARGIN.MARGIN;
  const response = (await axios.get(endpoints)).data;
  return response;
}

export async function saveRecykalMargin(payload) {
  const endpoints = ApiEndpoints.RECYKAL_MARGIN.MARGIN;
  const response = (await axios.post(endpoints, payload)).data;

  return response;
}

export async function deleteRecykalMargin(payload) {
  const endpoints = ApiEndpoints.RECYKAL_MARGIN.MARGIN;
  const response = await axios.delete(endpoints, { data: payload });
  return response;
}

export async function updateRecykalMargin(payload) {
  const endpoints = ApiEndpoints.RECYKAL_MARGIN.MARGIN;
  return (await axios.put(endpoints, payload)).data;
}

/**
 * LISTINGS
 */
export async function fetchKamListings(_params) {
  if (_params?.data?.headersFilter) {
    axios.defaults.headers.common.itemIds = _params?.data?.headersFilter?.items;
    axios.defaults.headers.common.materialTypeIds = _params?.data?.headersFilter?.materialId;
    delete _params?.data?.headersFilter;
  }
  const params = transformParams(_params?.data, FilterParamMap.LISTINGS);
  const endpoints = ApiEndpoints.LISTINGS.FETCH;
  return axios.get(endpoints, { params, cancelToken: _params?.source?.token });
}

export async function fetchLMetrics(_params) {
  const params = transformParamsForAggregator(_params, FilterParamMap.METRICS_LISTING);
  const endpoints = ApiEndpoints.LISTINGS.METRICS;
  return axios.get(endpoints, { params });
}

export async function fetchAvailableListings(params) {
  const endpoints = ApiEndpoints.ORDER.SALE.GET_AVAILABLE_LISTINGS;
  axios.defaults.headers.common.recyclerId = params.recyclerId;
  delete params.recyclerId;
  return axios.get(endpoints, { params });
}

export async function fetchAvailableListingsCreatePO(params) {
  const endpoints = ApiEndpoints.ORDER.SALE.GET_AVAILABLE_LISTINGS_PO;
  axios.defaults.headers.common.recyclerId = params?.data?.recyclerId;
  delete params?.data?.recyclerId;
  return axios.get(endpoints, {
    params: params?.data,
    cancelToken: params?.signal?.ourRequest?.token,
  });
}

export async function getAggregatorPayTerms(params) {
  const endpoints = ApiEndpoints.ORDER.SALE.GET_ALL_AGGREGATOR_PAY_TERMS;
  return (await axios.get(endpoints, { params })).data;
}

export async function updateAggPaymentTerm(payload, headersDelete) {
  const endpoints = ApiEndpoints.ORDER.SALE.GET_ALL_AGGREGATOR_PAY_TERMS;

  if (headersDelete !== null) {
    axios.defaults.headers.common.deleteIds = headersDelete;
  }

  return (await axios.post(endpoints, payload)).data;
}

export async function fetchKamListingsDetails(id) {
  const endpoints = buildUrl(ApiEndpoints.LISTINGS.DETAILS, { id });
  return (await axios.get(endpoints)).data;
}

export async function fetchKamListingsBuyerDetails(id) {
  const endpoints = buildUrl(ApiEndpoints.LISTINGS.LISTING_BUYER_ROUTE, { id });

  return (await axios.get(endpoints)).data;
}

export async function approveListings(id, params) {
  const endpoints = buildUrl(ApiEndpoints.LISTINGS.APPROVE_OR_REJECT_LISTING, { id });

  return (await axios.put(endpoints, null, { params })).data;
}

export async function rejectListings(id, params, payload) {
  const endpoints = buildUrl(ApiEndpoints.LISTINGS.REJECT_LISTING, { id });
  return (await axios.put(endpoints, payload, { params })).data;
}

export async function updateListings(payload) {
  const endpoints = ApiEndpoints.LISTINGS.UPDATE_LISTING;
  return (await axios.put(endpoints, payload)).data;
}
export async function addEditListings(payload) {
  const endpoints = ApiEndpoints.LISTINGS.ADD_EDIT_LISTING;
  const method = payload[0]?.id ? 'put' : 'post';
  // Perform POST if 'id' is empty, else PUT
  return (await axios[method](endpoints, payload)).data;
}
export async function scheduleListings(payload) {
  const endpoints = ApiEndpoints.LISTINGS.LISTING_SCHEDULE;
  return (await axios.put(endpoints, payload)).data;
}

export async function listingPriceupdate(payload) {
  const endpoints = ApiEndpoints.LISTINGS.LISTING_PRICE_UPDATE;
  return (await axios.put(endpoints, payload)).data;
}

export async function listingPriceTrend(payload) {
  const endpoints = ApiEndpoints.LISTINGS.LISTING_PRICE_TREND;
  return (await axios.put(endpoints, payload)).data;
}

export async function getOpenPO(id) {
  const endpoints = buildUrl(ApiEndpoints.LISTINGS.GET_PO, { id });
  return (await axios.get(endpoints)).data;
}

export async function disableListing(id, payload) {
  const endpoints = buildUrl(ApiEndpoints.LISTINGS.DISABLE_LISTING, { id });
  return (await axios.put(endpoints, payload)).data;
}

export async function assignPO(payload) {
  const endpoints = ApiEndpoints.LISTINGS.ASSIGN_OPEN_PO;
  return (await axios.post(endpoints, payload)).data;
}

export async function ListingAggregatorNoOfDays(payload) {
  const endpoints = ApiEndpoints.MARKET.LISTING.FETCH_AGGREGATOR_NO_OF_DAYS;
  return (await axios.post(endpoints, payload)).data;
}

export async function AssignRequisitionToListing(payload) {
  // eslint-disable-next-line max-len
  const endpoints = `${ApiEndpoints.LISTINGS.ASSIGN_REQUISITION}?listingId=${
    payload.listingId
  }&requisitionId=${payload.requisitionId}${
    payload.purchaseOrderId !== null ? `&purchaseOrderId=${payload.purchaseOrderId}` : ``
  }`;
  return (await axios.post(endpoints, null)).data;
}

// ASSIGN_REQUISITION

/**
 * CREDIT LIMIT & RECYCLER PAYMENTS APIS START
 */
export async function getAllRecyclerPaymentTerms(_params) {
  const params = transformParams(_params, FilterParamMap.RECYCLE_PAYMENT_TERM);
  const endpoints = ApiEndpoints.RECYCLER_PAYMENT_TERM.RECYCLER_PAYMENT_TERM;
  return (await axios.get(endpoints, { params })).data;
}

export async function saveRecyclerPaymentTerm(payload) {
  const endpoints = ApiEndpoints.RECYCLER_PAYMENT_TERM.RECYCLER_PAYMENT_TERM;
  const response = (await axios.post(endpoints, payload)).data;
  return response;
}

export async function updateRecyclerPaymentTerm(payload) {
  const endpoints = ApiEndpoints.RECYCLER_PAYMENT_TERM.RECYCLER_PAYMENT_TERM;
  return (await axios.put(endpoints, payload)).data;
}

export async function getRecyclerPaymentTermById(id) {
  const endpoints = buildUrl(ApiEndpoints.RECYCLER_PAYMENT_TERM.RECYCLER_PAYMENT_TERM, { id });
  return (await axios.get(endpoints)).data;
}
export async function saveCreditLimit(payload) {
  const endpoints = ApiEndpoints.RECYCLER_CREDIT_LIMIT.RECYCLER_CREDIT_LIMIT;
  const response = (await axios.post(endpoints, payload)).data;
  return response;
}

export async function fetchGlobalCreditLimit() {
  const endpoints = buildUrl(ApiEndpoints.RECYCLER_CREDIT_LIMIT.CREDIT_LIMIT_GLOBAL);
  return (await axios.get(endpoints)).data;
}

export async function fetchRecyclerCreditLimit(_params) {
  const params = transformParams(_params, FilterParamMap.CREDIT_LIMIT);
  const endpoints = buildUrl(ApiEndpoints.RECYCLER_CREDIT_LIMIT.RECYCLER_CREDIT_LIMIT);
  return (await axios.get(endpoints, { params })).data;
}

export async function fetchChangeLogMetrics(params) {
  const endpoints = buildUrl(ApiEndpoints.CHANGE_LOG_METRICS.CHANGE_LOG_METRICS);
  return (await axios.get(endpoints, { params })).data;
}

export async function fetchRecyclerReceivables(params) {
  const endpoints = buildUrl(ApiEndpoints.RECEIVABLES_SUMMARY.RECEIVABLES_SUMMARY);
  return (await axios.get(endpoints, { params })).data;
}

export async function fetchRecyclerReceivablesv2(params) {
  const endpoints = buildUrl(ApiEndpoints.RECEIVABLES_SUMMARY.RECEIVABLES_SUMMARY_V2);
  return (await axios.get(endpoints, { params })).data;
}

export async function fetchRecyclerReceivablesMetrics(params) {
  const endpoints = buildUrl(ApiEndpoints.RECEIVABLES_SUMMARY.RECEIVABLES_SUMMARY_METRICS);
  return (await axios.get(endpoints, { params })).data;
}

export async function fetchRecyclerLedger(params) {
  const endpoints = buildUrl(ApiEndpoints.RECYCLER_LEDGER.RECYCLER_LEDGER);
  return (await axios.get(endpoints, { params })).data;
}

export async function fetchRecyclerInvoices(params) {
  const endpoints = buildUrl(ApiEndpoints.RECEIVABLES_INVOICES_SUMMARY.INVOICES_SUMMARY);
  return (await axios.get(endpoints, { params })).data;
}

export async function postBulkPayments(payload) {
  const endpoints = buildUrl(ApiEndpoints.BULK_INVOICE_PAYMENTS.BULK_INVOICE_PAYMENTS);
  return (await axios.post(endpoints, payload)).data;
}

/**
 * CREDIT LIMIT & RECYCLER PAYMENTS APIS END
 */

export async function getAllPermissions(payload) {
  const endpoints = ApiEndpoints.USER.GET_ALL_PERMISSIONS;

  const response = (await axios.get(endpoints, payload)).data;

  return {
    data: [
      { id: 1, permission: 'TARGETS', title: 'Targets' },
      { id: 2, permission: 'SERVICE_PROVIDERS', title: 'Service Providers' },
      { id: 3, permission: 'WORK_ORDERS', title: 'Work Orders' },
      { id: 4, permission: 'CLIENTS', title: 'Clients' },
      { id: 5, permission: 'CLIENT_WORK_ORDERS', title: 'Client Work Orders' },
      { id: 6, permission: 'VIEW_DELIVERY', title: 'View Delivery' },
      { id: 7, permission: 'CREATE_DELIVERY', title: 'Create Delivery' },
      {
        id: 8,
        permission: 'UPLOAD_DOCUMENTS_AGAINST_DELIVERY',
        title: 'Upload Documents against delivery',
      },
      { id: 9, permission: 'APPROVE_DELIVERY', title: 'Approve Delivery' },
      { id: 10, permission: 'DISPUTE_DELIVERY', title: 'Dispute Delivery' },
      { id: 11, permission: 'APPROVE/DISPUTE_DOCUMENTS', title: 'Approve/Dispute Documents' },
      {
        id: 12,
        permission: 'ASSIGN_DELIVERY_TO_INCOMING_WORK_ORDERS',
        title: 'Assign delivery to incoming work orders',
      },
      { id: 13, permission: 'ROLES', title: 'Roles' },
      { id: 14, permission: 'USERS', title: 'Users' },
      { id: 15, permission: 'EXECUTION', title: 'Execution' },
      { id: 16, permission: 'DASHBOARD', title: 'Dashboard' },
      { id: 17, permission: 'PROFILE', title: 'Profile' },
      { id: 18, permission: 'REPORT', title: 'Report' },
    ],
  };
}

export async function getAllRoles(payload) {
  const endpoints = ApiEndpoints.USER.GET_ALL_PERMISSIONS;

  const response = (await axios.get(endpoints, payload)).data;

  return {
    data: [
      { id: 12, role: 'ROLE_ACS', title: 'ACS', isDefault: false },
      { id: 22, role: 'ROLE_KAM', title: 'KAM', isDefault: false },
      { id: 1, role: 'ROLE_ADMIN', title: 'Admin', isDefault: true },
      { id: 2, role: 'ROLE_OPERATIONS', title: 'Operation', isDefault: true },
      { id: 3, role: 'ROLE_AUDITOR', title: 'Auditor', isDefault: true },
    ],
  };
}

export async function getUserTypes(params) {
  const endpoints = ApiEndpoints.BUYERSELLERMAPPING.FETCH;
  return axios.get(endpoints, { params });
}

export async function getBsUserTypeById(id) {
  const endpoints = buildUrl(ApiEndpoints.BUYERSELLERMAPPING.FETCH, { id });
  return (await axios.get(endpoints)).data;
}

export async function createUserType(payload) {
  const endpoints = ApiEndpoints.BUYERSELLERMAPPING.FETCH;
  return (await axios.post(endpoints, payload)).data;
}

export async function updateUserType(payload) {
  const endpoints = ApiEndpoints.BUYERSELLERMAPPING.FETCH;
  if (payload?.deleteIds) {
    axios.defaults.headers.common.deleteIds = payload?.deleteIds;
  }
  return (await axios.put(endpoints, payload)).data;
}

export async function getProfileUserTypes(params) {
  const endpoints = ApiEndpoints.BUYERSELLERMAPPING.FETCH_PROFILE;
  return axios.get(endpoints, { params });
}

export async function getProfileUserTypeById(id) {
  const endpoints = buildUrl(ApiEndpoints.BUYERSELLERMAPPING.FETCH_PROFILE_BY_ID, { id });
  return (await axios.get(endpoints)).data;
}

export async function updateProfileUserType(payload) {
  const endpoints = ApiEndpoints.BUYERSELLERMAPPING.UPDATE_PROFILE;
  if (payload?.deleteIds) {
    axios.defaults.headers.common.deleteIds = payload?.deleteIds;
    axios.defaults.headers.common.customerId = payload?.customerId;
  }
  return (await axios.post(endpoints, payload?.bsMappingList)).data;
}

export async function toggleBuyerSellerListing(params) {
  const endpoints = ApiEndpoints.BUYERSELLERMAPPING.TOGGLE_LISTING;
  return axios.get(endpoints, { params });
}

export async function ChangeAggregatorUser(payload) {
  const endpoints = ApiEndpoints.BUYERSELLERMAPPING.CHANGE_AGGREGATOR_USER;
  if (payload?.id) {
    axios.defaults.headers.common.aggrId = payload?.id;
  }
  return axios.put(endpoints, payload);
}

export async function getAllLogisticItems(params) {
  const endpoints = ApiEndpoints.LOGISTIC_ITEM_MSTER.FETCH;
  return (await axios.get(endpoints, { params })).data;
}

export async function getAllLogisticItemsWithPagination(_params) {
  const params = transformParams(_params, FilterParamMap.LOGISTICS_ITEM);
  const endpoints = ApiEndpoints.LOGISTIC_ITEM_MSTER.GET;
  return axios.get(endpoints, { params });
}

export async function createLogisticItem(payload) {
  const endpoints = ApiEndpoints.LOGISTIC_ITEM_MSTER.CREATE;
  return (await axios.post(endpoints, payload)).data;
}

export async function generateTransporterInvoice(params) {
  const endpoints = ApiEndpoints.ZOHO_INVOICE.CREATE_TRANSPORTER_INVOICE;
  return (await axios.get(endpoints, { params })).data;
}
export async function confirmRecyclerInvoice(params) {
  const endpoints = ApiEndpoints.ZOHO_INVOICE.GET_INVOICE;
  return (await axios.get(endpoints, { params })).data;
}
export async function pullGSTFilingsFromAdequare(_params) {
  const params = transformParams(_params, FilterParamMap.GST_FILINGS);
  const endpoints = ApiEndpoints.GST_FILINGS.PULL_FROM_ADEQUARE;
  return axios.get(endpoints, { params });
}
export async function getGSTFilings(_params) {
  const params = transformParams(_params, FilterParamMap.GST_FILINGS);
  const endpoints = ApiEndpoints.GST_FILINGS.FETCH;
  return axios.get(endpoints, { params });
}

export async function getGSTFilingsReport(_params) {
  const params = transformParams(_params, FilterParamMap.GST_FILINGS);
  const endpoints = ApiEndpoints.GST_FILINGS.DOWNLOAD;
  return axios.get(endpoints, { responseType: 'blob', params });
}

export async function approveNotesToSellerBuyer(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.APPROVE_NOTES_SELLER_BUYER;
  return axios.put(endpoints, payload);
}

export async function createNotification(payload) {
  const endpoints = ApiEndpoints.USER.NOTIFICATIONS.CREATE;
  return (await axios.post(endpoints, payload)).data;
}

export async function createNotificationRoles(payload) {
  const endpoints = ApiEndpoints.USER.NOTIFICATIONS.CREATE_ROLES;
  return (await axios.post(endpoints, payload)).data;
}

export async function getAllNotifications(_params) {
  const endpoints = ApiEndpoints.USER.NOTIFICATIONS.GET;
  const params = transformParams(_params, FilterParamMap.NOTIFICATION);
  return axios.get(endpoints, { params });
}

export async function saveIncorrectDocs(id, payload) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.SAVE_INCORRECT_DOCS, { id });
  return (await axios.post(endpoints, payload)).data;
}

export async function getAllIncorrectDocs() {
  const endpoints = ApiEndpoints.ORDER.SALE.GET_ALL_INCORRECT_DOCS;
  return axios.get(endpoints, {});
}

export async function createCampaign(payload) {
  const endpoints = ApiEndpoints.CAMPAINGS_CREATION.CREATE_CAMPAINGS;
  return (await axios.put(endpoints, payload)).data;
}

export async function createCampaignDraft(payload) {
  const endpoints = ApiEndpoints.CAMPAINGS_CREATION.CREATE_CAMPAING_DRAFT;
  return (await axios.put(endpoints, payload)).data;
}

export async function getAllCampaigns(_params) {
  const endpoints = ApiEndpoints.CAMPAINGS_CREATION.CAMPAIGN_LIST;
  const params = transformParams(_params, FilterParamMap.CAMPAIGNS);
  return axios.get(endpoints, { params });
}

export async function getAllCampaignsById(id) {
  const endpoints = buildUrl(ApiEndpoints.CAMPAINGS_CREATION.CAMPAIGN_BY_ID, { id });

  return (await axios.get(endpoints)).data;
}

export async function updateCampaignStatus(id, _params) {
  const params = transformParams(_params, FilterParamMap.CAMPAIGNS_UPDATE);
  const endpoints = buildUrl(ApiEndpoints.CAMPAINGS_CREATION.CAMPAIGN_STATUS_UPDATE, { id });

  return (await axios.put(endpoints, null, { params })).data;
}

export async function getAllSellersByCampaignId(campaignId, _params) {
  const params = transformParams(_params, FilterParamMap.CAMPAIGNS_UPDATE);
  const endpoints = buildUrl(ApiEndpoints.CAMPAINGS_CREATION.CAMPAIGN_SELLER_INFO + campaignId, {
    params,
  });

  return axios.get(endpoints, { params });
}

export async function getAllCampaignsForUsers(_params) {
  const params = transformParams(_params, FilterParamMap.FIND_USER);
  const endpoints = ApiEndpoints.FIND_USER.FETCH_USER_CAMPAINGS;
  return axios.get(endpoints, { params });
}

export async function getSuggestedPrice(_params) {
  const params = transformParams(_params, FilterParamMap.FIND_USER);
  const endpoints = ApiEndpoints.FIND_USER.GET_SUGGESTED_PRICE;
  return axios.get(endpoints, { params });
}

export async function getAllAggregators() {
  const endpoints = ApiEndpoints.FIND_USER.FETCH_AGGREGATORS;
  return axios.get(endpoints, {});
}

export async function getAllAggregatorsByName(_params) {
  const endpoints = ApiEndpoints.FIND_USER.AGGREGATORS_BY_NAME;
  const params = transformParams(_params, FilterParamMap.FIND_USER);
  return axios.get(endpoints, { params });
}

export async function getAllPaymentTerms(_params) {
  const endpoints = ApiEndpoints.PAYMENT_TERMS.FETCH;
  const params = transformParams(_params, FilterParamMap.PAYMENT_TERM);
  return axios.get(endpoints, { params });
}

export async function addPaymentTerms(_params) {
  const endpoints = ApiEndpoints.PAYMENT_TERMS.ADD_PAYMENT_TERM;
  return (await axios.post(endpoints, _params)).data;
}
export async function editPaymentTerms(_params) {
  const endpoints = ApiEndpoints.PAYMENT_TERMS.EDIT_PAYMENT_TERM;
  return (await axios.put(endpoints, _params)).data;
}

export async function saveOrUpdateETA(_params) {
  const endpoints = ApiEndpoints.ORDER.SALE.TRACKING.UPDATE_ETA;
  return (await axios.post(endpoints, _params)).data;
}

export async function getAllTrackingMetrics(params) {
  const endpoints = ApiEndpoints.ORDER.SALE.TRACKING.GET_TRACKING_METRICS;
  return (await axios.get(endpoints, { params })).data;
}

export async function getTruckDriverDetailsBySaleOrder(saleOrderId) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.TRUCK_DRIVER_DETAILS_BY_SALE_ORDER, {
    saleOrderId,
  });
  return (await axios.get(endpoints)).data;
}

export async function UpdatePickupAddress(id, payload) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.UPDATE_PICKUP_ADDRESS, {
    id,
  });
  return (await axios.post(endpoints, payload)).data;
}

export async function fetchRecyclerReceivable(_params) {
  const params = transformParams(_params, FilterParamMap.RECEIVABLES_SUMMARY);
  const endpoints = buildUrl(ApiEndpoints.RECEIVABLES_SUMMARY.RECYCLERS_RECEIVABLES);
  return (await axios.get(endpoints, { params })).data;
}

export async function fetchRecyclerReceivableMetrics(_params) {
  const params = transformParams(_params, FilterParamMap.RECEIVABLES_SUMMARY);
  const endpoints = buildUrl(ApiEndpoints.RECEIVABLES_SUMMARY.RECYCLERS_RECEIVABLES_METRICS);
  return (await axios.get(endpoints, { params })).data;
}
export async function fetchRecyclerReceivablePayments(_params) {
  const params = transformParams(_params, FilterParamMap.RECEIVABLES_SUMMARY);
  const endpoints = buildUrl(ApiEndpoints.RECEIVABLES_SUMMARY.RECYCLERS_RECEIVABLES_PAYMENTS);
  return (await axios.get(endpoints, { params })).data;
}

export async function fetchRecyclerReceivableInvoices(_params) {
  const params = transformParams(_params, FilterParamMap.RECEIVABLES_SUMMARY);
  const endpoints = buildUrl(ApiEndpoints.RECEIVABLES_SUMMARY.RECYCLERS_RECEIVABLES_INVOICES);
  return axios.get(endpoints, { params });
}

export async function trackManual(saleOrderId, payload) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.TRACKING.TRACK_MANUAL, {
    saleOrderId,
  });
  return (await axios.post(endpoints, payload)).data;
}

export async function getAllTrackingDetails(params) {
  const endpoints = ApiEndpoints.TRACKING.TRACKING_DETAILS;
  return axios.get(endpoints, { params });
}

export async function getAggregatorPaymentsMetrics(_params) {
  const params = transformParams(_params, FilterParamMap.PAYMENTS_INFO);
  const endpoints = ApiEndpoints.ORDER.SALE.PAYMENTS.AGGREGATOR_PAYMENTS;
  return (await axios.get(endpoints, { params })).data;
}

export async function getAggregatorPaymentsList(_params) {
  const params = transformParams(_params, FilterParamMap.PAYMENTS_INFO);
  const endpoints = ApiEndpoints.ORDER.SALE.PAYMENTS.AGGREGATOR_PAYMENTS_LIST;

  return axios.get(endpoints, { params });
}

export async function getTrackingConfig() {
  const endpoint = buildUrl(ApiEndpoints.TRACKING_CONFIG.TRACKING_CONFIG);
  return (await axios.get(endpoint)).data;
}

export async function saveTrackingConfig(payload) {
  const endpoint = buildUrl(ApiEndpoints.TRACKING_CONFIG.TRACKING_CONFIG);
  return (await axios.put(endpoint, payload)).data;
}

export async function getTrackingConfigLogs(params) {
  const endpoint = buildUrl(ApiEndpoints.TRACKING_CONFIG.TRACKING_CONFIG_LOGS);
  return (await axios.get(endpoint, { params })).data;
}

export async function pickupAddressLogs(params) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.PICKUP_ADDRESS_LOGS);
  return (await axios.get(endpoints, { params })).data;
}

export async function getTransporterPaymentsList(_params) {
  const params = transformParams(_params, FilterParamMap.PAYMENTS_INFO);
  const endpoints = ApiEndpoints.ORDER.SALE.PAYMENTS.TRANSPORTER_PAYMENTS_LIST;
  return axios.get(endpoints, { params });
}

export async function getTransporterPaymentsMetrics(_params) {
  const params = transformParams(_params, FilterParamMap.PAYMENTS_INFO);
  const endpoints = ApiEndpoints.ORDER.SALE.PAYMENTS.TRANSPORTER_PAYMENTS_METRICS;
  return (await axios.get(endpoints, { params })).data;
}

export async function getAllTransportersByName(params) {
  const endpoints = ApiEndpoints.TRANSPORTERS.FETCH_BY_NAME;
  return axios.get(endpoints, { params });
}

export async function getMaterialTypesList() {
  const endpoints = ApiEndpoints.ITEMS_MODULE.MATERIAL_TYPE_TINY;
  return (await axios.get(endpoints)).data;
}

export async function getTrackingView(params) {
  axios.defaults.headers.common.viewName = params?.viewName;
  const endpoints = ApiEndpoints.TRACKING.TRACKING_VIEW;
  return axios.get(endpoints);
}

export async function getMarketplaceLogs(_params) {
  const params = transformParams(_params, FilterParamMap.MARKETPLACE_LOGS);
  const endpoints = ApiEndpoints.MARKETPLACE_LOGS.GET_LOGS;
  return (await axios.get(endpoints, { params })).data;
}

export async function updateBankDetails(payload) {
  const endpoints = ApiEndpoints.BANK_DETAILS.UPADTE_BANK_DETAILS;
  return (await axios.put(endpoints, payload)).data;
}

export async function updateAggregatorBankStatus(id, bankStatus, payload) {
  let endpoints = buildUrl(ApiEndpoints.AGGREGATOR.UPDATE_SELLER, { id });
  endpoints = `${endpoints}?bankStatus=${bankStatus}`;
  return (await axios.put(endpoints, payload)).data;
}

export async function updateGSTINStatus(payload) {
  // eslint-disable-next-line max-len
  const endpoints = `${ApiEndpoints.AGGREGATOR.FETCH_GSTIN}?sellerId=${payload?.sellerId}&gstIn=${payload.gstIn}&shipmentId=${payload.saleOrderId}`;
  return (await axios.put(endpoints)).data;
}

export async function assignTruckFromKamorApp(payload) {
  const endpoints = ApiEndpoints.ORDER.SALE.ASSIGN_TRUCK;
  return (await axios.post(endpoints, payload)).data;
}

export async function getUsersByRole(params) {
  const endpoints = ApiEndpoints.USER.GET_USERS_BY_ROLE;
  return axios.get(endpoints, { params: { roles: params?.roles } });
}

export async function getPOCByState(params) {
  const endpoints = ApiEndpoints.POINT_OF_CONTACT.GET_POC_BY_STATE;
  return (await axios.get(endpoints, { params })).data;
}

export async function getUpdateSPOC(params) {
  const endpoints = ApiEndpoints.POINT_OF_CONTACT.UPDATE_SPOC;
  return (await axios.get(endpoints, { params })).data;
}

export async function getPOCByRSM(params) {
  const endpoints = ApiEndpoints.POINT_OF_CONTACT.GET_POC_BY_RSM;
  return (await axios.get(endpoints, { params })).data;
}

export async function calculateQcAmount(action, payload) {
  const endpoints = buildUrl(
    ApiEndpoints.ORDER.SALE.SALE_ORDER_RECYCLER_QC_PARAMS.CALCULATE_QC_AMOUNT,
    { action }
  );
  return (await axios.post(endpoints, payload)).data;
}

export async function saveRecyclerQcParams(payload) {
  const endpoints = buildUrl(
    ApiEndpoints.ORDER.SALE.SALE_ORDER_RECYCLER_QC_PARAMS.SAVE_RECYCLER_QC_PARAMS
  );
  return (await axios.put(endpoints, payload))?.data;
}

export async function getRecyclerQcParams(params) {
  const endpoints = ApiEndpoints.ORDER.SALE.SALE_ORDER_RECYCLER_QC_PARAMS.GET_RECYCLER_QC_PARAMS;
  return axios.get(endpoints, { params });
}

export async function setMarginApproval(params) {
  const endpoints = ApiEndpoints.ORDER.SALE.MARGIN_DIP;
  return axios.get(endpoints, { params });
}

export async function getAllServiceRoles(params) {
  const endpoints = ApiEndpoints?.USER_AND_ROLES?.GET_ROLES;
  return axios.get(endpoints, { params });
}

export async function getAllRolesGroup(params) {
  const endpoints = ApiEndpoints?.USER_AND_ROLES?.GET_ROLES_GROUP;
  return axios.get(endpoints, { params });
}

export async function getRoleByName(name) {
  const endpoints = buildUrl(ApiEndpoints?.USER_AND_ROLES?.GET_ROLES_BY_NAME, { name });
  return (await axios.get(endpoints)).data;
}

export async function createNewRole(payload) {
  const endpoints = ApiEndpoints?.USER_AND_ROLES?.CREATE_NEW_ROLE;
  return (await axios.post(endpoints, payload))?.data;
}

export async function editRole(payload) {
  const endpoints = ApiEndpoints?.USER_AND_ROLES?.EDIT_ROLE;
  return (await axios.put(endpoints, payload))?.data;
}

export async function getAllGroups(params) {
  const endpoints = ApiEndpoints?.USER_AND_ROLES?.GET_ALL_GROUPS;
  return axios.get(endpoints, { params });
}

export async function getAllModules(params) {
  const endpoints = ApiEndpoints?.USER_AND_ROLES?.GET_ALL_MODULES;
  return axios.get(endpoints, { params });
}

export async function getAllSystemUsers(_params) {
  const endpoints = ApiEndpoints?.USER_AND_ROLES?.GET_ALL_USERS_LIST;
  const params = transformParams(_params, FilterParamMap?.USERS);
  return axios.get(endpoints, { params });
}

export async function createLoginLogoutLogs(payload) {
  const endpoints = ApiEndpoints?.USER_AND_ROLES?.LOGS_LOGIN_LOGOUT;
  const params = transformParams(payload, FilterParamMap?.USERS);
  return axios.post(endpoints, null, {
    params,
  });
}

export async function updateDocument(payload) {
  const endpoints = ApiEndpoints?.DOCUMENT.UPDATE;
  return axios.put(endpoints, payload);
}

export async function getDailyPrices(_params) {
  const endpoints = ApiEndpoints?.DAILY_PRICES?.GET_DAILY_PRICES;
  const params = transformParams(_params, FilterParamMap?.DAILY_PRICES);
  return axios.get(endpoints, { params });
}

export async function updateDailyPrice(payload) {
  const endpoints = ApiEndpoints?.DAILY_PRICES?.UPDATE_DAILY_PRICES;
  return (await axios.post(endpoints, payload))?.data;
}

export async function verifyVehicleDetails(payload) {
  const endpoints = ApiEndpoints?.ORDER?.SALE?.VERIFY_VEHICLE_DETAILS;
  return (await axios.put(endpoints, payload))?.data;
}

export async function approveRejectDocumentDetails(payload) {
  const endpoints = ApiEndpoints?.ORDER?.SALE?.APPROVE_REJECT_DOCUMENT;
  return (await axios.put(endpoints, payload))?.data;
}

export async function generateInvoices(params) {
  const endpoints = ApiEndpoints.ORDER.SALE.GENERATE_INVOICES;
  return (await axios.put(endpoints, null, { params }))?.data;
}

export async function getMarketplaceLogsByCriteria(_params) {
  const params = transformParams(_params, FilterParamMap.MARKETPLACE_LOGS);
  const endpoints = ApiEndpoints.MARKETPLACE_LOGS.GET_LOGS_BY_CRITERIA;
  return (await axios.get(endpoints, { params })).data;
}

export async function updateEwaybillNo(payload) {
  const endpoint = `${ApiEndpoints.ORDER.SALE.UPDATE_EWAYBILL_NUMBER}?
sellerId=${payload?.sellerId}&shipmentId=${payload?.shipmentId}
&eWayBillNumber=${payload?.sellerEwayBillNo}`;
  return (await axios.put(endpoint)).data;
}

export async function getRecykalMarginQrf(_params) {
  const endpoints = ApiEndpoints.RECYKAL_MARGIN.MARGIN_QRF;
  const params = transformParams(_params, FilterParamMap.RECYKAL_MARGIN_QRF);
  return axios.get(endpoints, { params });
}

export async function updateSellerWeighBridgeInfo(payload) {
  const endpoint = ApiEndpoints.ORDER.SALE.SELLER_WEIGHBRIDGE_INFO;
  const result = await axios.put(endpoint, payload);
  return result?.data;
}

export async function updateSellerEInvoice(payload) {
  const endpoint = `${ApiEndpoints?.AGGREGATOR?.UPDATE_AGGREGATOR_E_INVOICE}?
sellerId=${payload?.sellerId}&eInvoiceTag=${payload?.eInvoiceTag}`;
  return (await axios.post(endpoint))?.data;
}

export async function moveListingToItad(payload) {
  const endpoints = ApiEndpoints.LISTINGS.INITIATE_AUCTION;
  return (await axios.post(endpoints, payload)).data;
}

export async function getAssetItemList(params) {
  const endpoints = ApiEndpoints?.LISTINGS?.ASSET_ITEM_LIST;
  return axios.get(endpoints, { params });
}

export async function skipAggregatorDetails(params) {
  const endpoints = `${ApiEndpoints.AGGREGATOR.SKIP_AGGREGATOR_DETAILS}?
sellerId=${params?.sellerId}&skipDetailsFlag=${params?.skipDetailsFlag}`;
  return axios.post(endpoints);
}

export async function createAggregatorOnBoarding(param) {
  const params = transformParams(param, FilterParamMap.APP_LEADS);
  const endpoints = ApiEndpoints.AGGREGATOR.AGGREGATOR_ONBOARDING;
  const response = await axios.post(endpoints, null, {
    params,
  });
  return response;
}

export async function assignTargets(payload) {
  const endpoints = ApiEndpoints.REQUISITION.ASSIGN_TARGETS;
  const response = await axios.post(endpoints, payload);
  return response?.data;
}

export async function updateTarget(payload) {
  const endpoints = ApiEndpoints.REQUISITION.UPDATE_TARGET;
  const response = await axios.put(endpoints, payload);
  return response?.data;
}

export async function getAggregatorDocumentsList(params) {
  const endpoints = ApiEndpoints.AGGREGATOR.AGGREGATOR_DOCUMENTS_LIST;
  return axios.get(endpoints, { params });
}

export async function getVendorSegmentation(_params) {
  const params = _params ? transformParams(_params, FilterParamMap.SEGMENTATION_PARAMS) : '';
  const endpoints = ApiEndpoints.SEGMENTATION.GET_VENDOR_SEGMENT;
  return axios.get(endpoints, { params });
}

export async function saveVendorSegmentation(payload) {
  const endpoints = ApiEndpoints.SEGMENTATION.GET_VENDOR_SEGMENT;
  return axios.post(endpoints, payload);
}

export async function updateVendorSegmentation(payload) {
  const endpoints = ApiEndpoints.SEGMENTATION.GET_VENDOR_SEGMENT;
  return axios.put(endpoints, payload);
}

export async function getActiveSellerTypes() {
  const endpoints = ApiEndpoints.SEGMENTATION.GET_ACTIVE_SELLER_TYPES;
  return axios.get(endpoints);
}

export async function getSegmentCondition() {
  const endpoints = ApiEndpoints.SEGMENTATION.GET_SEGMENT_CONDITIONS;
  return axios.get(endpoints);
}

export async function getSegmentLogs(params) {
  const endpoints = `${ApiEndpoints.SEGMENTATION.GET_LOGS}/${params}`;
  return axios.get(endpoints);
}

export async function scheduleKyc(params) {
  const endpoint = `${ApiEndpoints.AGGREGATOR.SEND_SCHEDULED_KYC_DETAILS}?id=${params?.id}`;
  return (await axios.post(endpoint, params)).data;
}

export async function getVkycDetails(params) {
  const endpoint = ApiEndpoints.AGGREGATOR.FETCH_VKYC_DETAILS;
  return axios.get(endpoint, { params });
}

export async function postVKYCStatus(params) {
  const endpoint = ApiEndpoints.AGGREGATOR.POST_VKYC_STATUS;
  return (await axios.post(endpoint, null, { params })).data;
}

export async function getSystemConfigsByConfigName(payload) {
  const endpoints = ApiEndpoints.RECYCLER.GET_SYSTEM_CONFIGS;
  const response = await axios.get(endpoints, { params: payload });
  return response?.data;
}
export async function userAvailability(params) {
  const endpoints = ApiEndpoints.ORDER_ALLOCATION.USER_AVAILABLE;
  return axios.post(endpoints, null, { params });
}
export async function updateUserAvailability(params) {
  const endpoints = ApiEndpoints.ORDER_ALLOCATION.UPDATE_USER_AVAILABLE;
  return axios.put(endpoints, params);
}
export async function getTotalUsers(params) {
  const endpoints = ApiEndpoints.ORDER_ALLOCATION.TOTAL_USERS;
  return axios.get(endpoints, null, { params });
}
export async function assignSaleOrder(params) {
  const endpoints = ApiEndpoints.ORDER_ALLOCATION.ASSIGN_SALE_ORDER;
  return axios.post(endpoints, null, { params });
}
export async function getAssignedSaleOrders(_params) {
  const params = _params ? transformParams(_params, FilterParamMap.ORDER_ALLOCATION_PARAMS) : '';
  const endpoints = ApiEndpoints.ORDER_ALLOCATION.GET_SALE_ORDER_ALLOCATION;
  return axios.get(endpoints, { params });
}

export async function holdAssignedSaleOrder(params) {
  const endpoints = ApiEndpoints.ORDER_ALLOCATION.HOLD_ASSIGNED_SALEORDER;
  return axios.post(endpoints, null, { params });
}

export async function approvePayment(params) {
  const endpoints = ApiEndpoints.ORDER.SALE.PAYMENTS.APPROVE_PAYMENT;
  return (await axios.post(endpoints, null, { params })).data;
}

export async function getSaleOrderAllocationLogs(_params) {
  const endpoints = `${ApiEndpoints.ORDER_ALLOCATION.SALE_ORDER_ALLOCATION_LOGS}/${_params}`;
  return axios.get(endpoints);
}

export async function getOutStandingAmount(params) {
  const endpoints = `${ApiEndpoints.AGGREGATOR.UPDATE_AMOUNT}?vendorZohoId=${params}`;
  return axios.get(endpoints);
}

export async function readyForOnboarding(action, _params) {
  const endpoints = `${ApiEndpoints.AGGREGATOR.READY_FOR_ONBOARDING}/${action}`;
  return axios.post(endpoints, null, { params: _params });
}

export async function fetchAppUpdates(_params) {
  const params = transformParams(_params, FilterParamMap.MANAGE_UPDATES);
  const endpoints = ApiEndpoints.MANAGE_UPDATES.UPDATES_LIST;
  return axios.get(endpoints, { params });
}

export async function createAppUpdates(body) {
  const endpoint = ApiEndpoints.MANAGE_UPDATES.CREATE_UPDATES;
  const response = (await axios.post(endpoint, body)).data;
  return response;
}
export async function fetchUpdateDetailsByID(id) {
  const endpoints = buildUrl(ApiEndpoints.MANAGE_UPDATES.GET_UPDATES_ID, { id });
  return (await axios.get(endpoints)).data;
}

export async function financeDashboard(payload) {
  // eslint-disable-next-line max-len
  const endpoints = `${ApiEndpoints.FINANCE.DASHBOARD}?startDateFilter=${payload.startDateFilter}&endDateFilter=${payload.endDateFilter}`;
  return axios.get(endpoints);
}

export async function getFastTagTraces(params) {
  const endpoints = ApiEndpoints.ORDER.SALE.GET_FASTTAG_TRACES;
  return axios.get(endpoints, { params });
}

export async function updateFastTagTraces(params) {
  const endpoint = `${ApiEndpoints.ORDER.SALE.UPDATE_FASTTAG_TRACES}?shipmentId=${params}`;
  return axios.put(endpoint);
}

export async function fetchSalesOrders(_params) {
  const params = transformParams(_params, FilterParamMap.SALES_ORDERS);
  const endpoints = ApiEndpoints.ORDER.PURCHASE.FETCH_SALES_ORDERS_LIST;
  return axios.get(endpoints, { params });
}

export async function createShipment(payload) {
  const endpoint = ApiEndpoints.ORDER.PURCHASE.CREATE_SHIPMENT;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function verifySellerDocuments(payload) {
  const endpoints = ApiEndpoints?.ORDER?.SALE?.VERIFY_SELLER_DOCUMENTS;
  return (await axios.put(endpoints, payload))?.data;
}

export async function addPoItemDetails(payload) {
  const endpoints = ApiEndpoints.ORDER.PURCHASE.ADD_EDIT_PO_ITEM_DETAILS;
  const response =
    payload?.[0]?.id !== null
      ? (await axios.put(endpoints, payload))?.data
      : (await axios.post(endpoints, payload)).data;
  return response;
}

export async function deletePoItemDetails(payload) {
  const endpoints = ApiEndpoints.ORDER.PURCHASE.DELETE_PO_ITEM_DETAILS;
  const response = (await axios.put(endpoints, payload))?.data;
  return response;
}

export async function recyclerOnboardingGstinFetch(id) {
  const endpoints = ApiEndpoints.RECYCLER.RECYCLER_ONBOARDING_GSTIN_FETCH;
  const response = (await axios.get(endpoints + id)).data;
  return response;
}

export async function markAsCompleted(payload) {
  const endpoint = ApiEndpoints.ORDER.SALE.MARK_AS_COMPLETED;
  return axios.put(endpoint, payload);
}

export async function completeShipmentAllocation(params) {
  const endpoints = ApiEndpoints.ORDER_ALLOCATION.COMPLETE_SHIPMENT_ALLOCATION;
  return axios.post(endpoints, null, { params });
}

export async function getAuditLogs(params) {
  const endpoints = ApiEndpoints.AUDIT_LOGS.GET_AUDIT_LOGS;
  return axios.get(endpoints, { params });
}
export async function getRolesAuditLogs(params) {
  const endpoints = ApiEndpoints.AUDIT_LOGS.GET_ROLES_AUDIT_LOGS;
  return axios.get(endpoints, { params });
}

export async function getVehiclesList(_params) {
  const params = transformParams(_params, FilterParamMap.VEHICLES);
  const endpoints = ApiEndpoints.ORDER.SALE.GET_VEHICLES;
  return axios.get(endpoints, { params });
}

export async function getVehicleDetailsById(id) {
  const endpoints = buildUrl(ApiEndpoints.ORDER.SALE.GET_VEHICLE_INFO, { id });
  return (await axios.get(endpoints)).data;
}

export async function getDocumentMasterSectionInfo() {
  const endpoints = ApiEndpoints.SHIPMENT_DOCUMENTS_CONFIGURATIONS.GET_MATERIALS_MASTER;
  return (await axios.get(endpoints)).data;
}

export async function postDocumentsMasterSection(payload) {
  const endpoints = ApiEndpoints.SHIPMENT_DOCUMENTS_CONFIGURATIONS.POST_MATERIALS_MASTER;
  return (await axios.post(endpoints, payload)).data;
}

export async function getMaterialsConfigList(params) {
  const endpoints = ApiEndpoints.SHIPMENT_DOCUMENTS_CONFIGURATIONS.GET_MATERIALS_LIST;
  return axios.get(endpoints, { params });
}

export async function getMaterialsConfigById(materialId) {
  const endpoints = buildUrl(ApiEndpoints.SHIPMENT_DOCUMENTS_CONFIGURATIONS.GET_DOCS_BY_ID, {
    materialId,
  });
  return (await axios.get(endpoints)).data;
}

export async function updateMaterialsConfigById(materialId, payload) {
  const endpoints = buildUrl(ApiEndpoints.SHIPMENT_DOCUMENTS_CONFIGURATIONS.PUT_DOCS_BY_ID, {
    materialId,
  });
  return (await axios.put(endpoints, payload)).data;
}
