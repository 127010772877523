/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Loader, PageContainer } from '../../shared/components';
import { SecondaryButton } from '../../shared/components/GenericComponent';
import ShipmentDocsConfigList from './ShipmentDocsConfigList';
import DocumentSectionMainPage from './DocumentSectionMainPage';
import { useShipmentDocumentsConfiguration } from '../../hooks/hooks';

const ShipmentDocumentsConfig = () => {
  const [
    { getMaterialConfigListError, getMaterialConfigListLoading, getMaterialConfigListValue },
    { doGetMaterialsConfigList },
  ] = useShipmentDocumentsConfiguration();

  const history = useHistory();

  useEffect(() => {
    const params = {
      page: 0,
      size: 20,
    };
    doGetMaterialsConfigList(params);
  }, []);

  const handleNavigateDocSectionMaster = () => {
    history.push({
      pathname: '/systemconfig/documents-configure/master',
      state: {
        isMaster: true,
      },
    });
  };

  return (
    <PageContainer>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Row>
          <Col className="ml-1 mb-1" md={12}>
            <Row className="align-items-center">
              <Col>
                <h3 className="mt-1 font-bold">Shipment Documents Configurations</h3>
                <p style={{ fontSize: '16px' }}>
                  Manage the documents required for business vertical onboarding based on the seller
                  type
                </p>
              </Col>
              <Col className="text-right">
                <SecondaryButton
                  buttonStyle={{ borderRadius: '8px' }}
                  label="Document Section Master"
                  className="font-semibold ButtonLoading"
                  onClick={() => handleNavigateDocSectionMaster()}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="mt-4" style={{ flex: 1, overflow: 'hidden' }}>
          <ShipmentDocsConfigList
            data={getMaterialConfigListValue}
            isLoading={getMaterialConfigListLoading}
          />
        </div>
      </div>
    </PageContainer>
  );
};

export default ShipmentDocumentsConfig;
