import { publish } from './EventManager';

export const EVENTS = {
  ORDER_CHANGED: 'ORDER_CHANGED',
};
export const onSaleOrderChanged = (result) => {
  publish('SALE_ORDER', EVENTS.ORDER_CHANGED, result);
};

export const onPurchaseOrderChanged = (result) => {
  publish('PURCHASE_ORDER', EVENTS.ORDER_CHANGED, result);
};
