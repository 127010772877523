/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
import React, { useState, useMemo, useEffect } from 'react';
import './DocumentConfigTable.scss';
import { EditGrey } from '../../../assets/img/imageExport/images';
import { DoShow } from '../GenericComponent';
import { DATAPOINTS_RELATED_TO_E_WAY_BILL_DOC, DOC_TYPES } from '../../constants/Constants';

const DocumentConfigTable = ({
  data,
  isDocumentsTable = false,
  isMaster = false,
  setDocumentAndDataPointIndex = () => {},
  setShowDataPointModal = () => {},
  setShowDocumentModal = () => {},
  setSectionIndex = () => {},
  secIndex,
  formik,
  handleRefresh,
  ...props
}) => {
  const actionOptions = [
    { id: 'mandatory', value: 'Mandatory' },
    { id: 'optional', value: 'Optional' },
    { id: 'notRequired', value: 'Not Required' },
  ];
  const [selectedActions, setSelectedActions] = useState(
    data.reduce((acc, row, index) => {
      const formattedStatus =
        actionOptions.find((option) => option.id === row.status)?.value || 'Not Required';
      acc[index] = formattedStatus;
      return acc;
    }, {})
  );

  const handleActionChange = (rowIndex, action) => {
    setSelectedActions((prev) => ({
      ...prev,
      [rowIndex]: action.value,
    }));
  };

  const columns = [
    {
      Header: 'S.No',
      accessor: 'S.No',
      Cell: ({ value }) => <div style={{ textAlign: 'left' }}>{value}</div>,
    },
    {
      Header: isDocumentsTable ? 'Document Name' : 'Datapoints',
      accessor: 'Datapoints',
      Cell: ({ value }) => <div style={{ textAlign: 'left' }}>{value}</div>,
    },
    {
      Header: 'Data Type',
      accessor: 'Data Type',
      Cell: ({ value }) => <div style={{ textAlign: 'left' }}>{value}</div>,
    },
    {
      Header: 'Properties',
      accessor: 'Properties',
      Cell: ({ value }) => <div style={{ textAlign: 'left' }}>{value}</div>,
    },
    {
      Header: 'Action',
      Cell: ({ rowIndex }) => {
        return (
          <div className="d-flex justify-content-around" style={{ justifyContent: 'flex-start' }}>
            <DoShow
              show={
                isDocumentsTable
                  ? formik?.values?.documentConfigDetails?.[secIndex]?.documents?.[rowIndex]
                      ?.isDefault
                  : formik?.values?.documentConfigDetails?.[secIndex]?.dataPoints?.[rowIndex]
                      ?.isDefault
              }>
              <EditGrey
                onClick={() => {
                  setDocumentAndDataPointIndex(rowIndex);
                  if (isDocumentsTable) {
                    setShowDocumentModal(true);
                    setSectionIndex(secIndex);
                  } else {
                    setShowDataPointModal(true);
                    setSectionIndex(secIndex);
                  }
                }}
              />
            </DoShow>
          </div>
        );
      },
    },
    {
      Header: 'Actions',
      accessor: 'Actions',
      Cell: ({ rowIndex }) => {
        return (
          <div className="d-flex justify-content-around" style={{ justifyContent: 'flex-start' }}>
            {actionOptions.map((action) => (
              <label
                key={action.id}
                className={`action-radio-label ${
                  selectedActions[rowIndex] === action.value ? 'selected' : ''
                }`}>
                <input
                  disabled={
                    !isDocumentsTable &&
                    DATAPOINTS_RELATED_TO_E_WAY_BILL_DOC.includes(
                      formik?.values?.documentConfigDetails?.[secIndex]?.dataPoints?.[rowIndex]
                        ?.dataPointEnum
                    )
                  }
                  type="radio"
                  name={`action-${rowIndex}`}
                  value={action.value}
                  checked={selectedActions[rowIndex] === action.value}
                  onChange={() => {
                    handleActionChange(rowIndex, action);
                    if (isDocumentsTable) {
                      formik?.setFieldValue(
                        `documentConfigDetails.[${secIndex}].documents.[${rowIndex}].documentStatus`,
                        action.id
                      );
                      if (
                        formik?.values?.documentConfigDetails?.[secIndex]?.documents?.[rowIndex]
                          ?.documentEnum === DOC_TYPES.SELLER_EWAY_BILL
                      ) {
                        formik?.values?.documentConfigDetails?.[secIndex]?.dataPoints?.forEach(
                          (dataPoint, index) => {
                            if (
                              DATAPOINTS_RELATED_TO_E_WAY_BILL_DOC.includes(
                                dataPoint?.dataPointEnum
                              )
                            ) {
                              formik?.setFieldValue(
                                `documentConfigDetails.[${secIndex}].dataPoints.[${index}].dataPointStatus`,
                                action.id
                              );
                            }
                          }
                        );
                        handleRefresh();
                      }
                    } else {
                      formik?.setFieldValue(
                        `documentConfigDetails.[${secIndex}].dataPoints.[${rowIndex}].dataPointStatus`,
                        action.id
                      );
                    }
                  }}
                  className="action-radio-input"
                />
                <span className="action-radio-circle" />
                {action.value}
              </label>
            ))}
          </div>
        );
      },
    },
  ];

  const filteredColumns = useMemo(() => {
    if (isMaster) {
      if (isDocumentsTable) {
        return columns.filter((col) => ['S.No', 'Document Name', 'Action'].includes(col.Header));
      } else {
        return columns.filter((col) =>
          ['S.No', 'Datapoints', 'Data Type', 'Properties', 'Action'].includes(col.Header)
        );
      }
    } else {
      if (isDocumentsTable) {
        return columns.filter((col) => ['S.No', 'Document Name', 'Actions'].includes(col.Header));
      } else {
        return columns.filter((col) =>
          ['S.No', 'Datapoints', 'Data Type', 'Properties', 'Actions'].includes(col.Header)
        );
      }
    }
  }, [isDocumentsTable, columns]);

  return (
    <div className="DocumentConfigTable table-responsive">
      <table className="table table-bordered table-striped">
        <thead style={{ backgroundColor: '#5b7682', color: 'white' }}>
          <tr>
            {filteredColumns.map((col, index) => (
              <th key={index} className="text-center">
                {col.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td colSpan={filteredColumns.length} className="text-center empty-data">
                No {isDocumentsTable ? 'Document' : 'Datapoint'} available Yet!
              </td>
            </tr>
          ) : (
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {filteredColumns.map((col, colIndex) => (
                  <td key={colIndex} className="data-cell">
                    {col.Cell
                      ? col.Cell({ rowIndex, value: row[col.accessor] })
                      : row[col.accessor] || '-'}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DocumentConfigTable;
